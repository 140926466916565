angular.module('user.notifications', []).controller('UserNotificationController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  var icons = {
    FRIEND_REQUEST: 'fa-users',
    NEW_EMAIL: 'fa-envelope',
    WARNING: 'fa-exclamation-triangle',
    CLICK: 'fa-hand-pointer-o',
    TASK_ASSIGNED: 'fa-thumb-tack'
  }
  $scope.getIcon = (type) => {
    return icons[type] == null ? 'fa-bell-o' : icons[type];
  }
  $scope.notifications = [];
  $scope.notificationsLoading = false;
  $scope.loadNotifications = () => {
    $scope.notificationsLoading = true;
    $http({
      url: "/api/user/notifications/",
      method: "GET"
    }).then((response) => {
      $scope.notifications = response.data;
      $scope.notificationsLoading = false;
    });
  }
  $scope.deleteNotification = (notification) => {
    $http({
      url: "/api/user/notifications/",
      method: "DELETE",
      params: {
        notificationId: notification.id
      }
    }).then((response) => {
      $scope.loadNotifications();
    });
  }
  $scope.deleteAllNotifications = () => {
    $http({
      url: "/api/user/notifications/clear/",
      method: "POST"
    }).then((response) => {
      $scope.loadNotifications();
    });
  }
  $scope.$on('receivedPushNotification', (event, notification) => {
    $scope.loadNotifications();
  });
  $localization.ready().then(() => {
    moment.locale($localization.getLanguage());
    $scope.loadNotifications();
  });
}]);