angular.module('degrees', ['twbs']).controller('DegreesController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.confirmDialog = {};
  $scope.showProgress = false;
  $scope.loadDegrees = () => {
    $http({
      url: "/api/enum/degrees/",
      method: "GET"
    }).then((response) => {
      $scope.degrees = response.data;
    });
  }
  $scope.deleteDegree = (degree) => {
    var messages = {
      title: $localization.get('admin.enums.degree.delete.title'),
      message: $localization.get('admin.enums.degree.delete.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      if (degree.id != null) {
        $scope.showProgress = true;
        $http({
          url: "/api/enum/degrees/",
          method: "DELETE",
          params: {
            degreeId: degree.id,
          }
        }).then((response) => {
          $timeout(() => {
            $scope.alertDegrees.open('SUCCESS', response.data.message, 12000);
            $scope.degrees = $scope.degrees.filter((obj) => {
              return obj.$$hashKey != degree.$$hashKey;
            });
            $scope.showProgress = false;
          }, 1000);
        }, (response) => {
          $timeout(() => {
            $scope.alertDegrees.open('WARNING', response.data.message, 12000);
            $scope.showProgress = false;
          }, 1000);
        });
      } else {
        $scope.degrees = $scope.degrees.filter((obj) => {
          return obj.$$hashKey != degree.$$hashKey;
        });
      }
    });
  }
  $scope.addDegree = (place) => {
    $scope.degrees.push({
      type: 'PREFIX',
      degree: '',
      description: '',
      sort: place
    });
    $scope.degrees.sort((a, b) => {
      return a.sort - b.sort;
    });
    $scope.degrees.forEach((elem, i) => {
      elem.sort = i;
    });
  }
  $scope.saveDegrees = () => {
    $scope.showProgress = true;
    $http({
      url: "/api/enum/degrees/",
      method: "POST",
      data: $scope.degrees
    }).then((response) => {
      $timeout(() => {
        $scope.loadDegrees();
        $scope.alertDegrees.open('SUCCESS', response.data.message, 12000);
        $scope.showProgress = false;
      }, 1000);
    }, (response) => {
      $timeout(() => {
        $scope.alertDegrees.open('DANGER', response.data.message, 12000);
        $scope.showProgress = false;
      }, 1000);
    });
  }
  $localization.ready().then(() => {
    $scope.loadDegrees();
  });
}]);