angular.module('alertic', ['twbs']).controller('AlerticController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.activityLoading = false;
  $scope.newAlerteecLoading = false;
  $scope.editAlerteecLoading = false;
  $scope.showEditAlerts = false;
  $scope.newAlerteecProgressPercent = 0;
  $scope.editAlerteecProgressPercent = 0;
  $scope.activitiesPerPage = 12;
  $scope.currentActivityLinkId = 0;
  $scope.currentLinkId = 0;
  $scope.alerteecCanEdit = $('alerteec-can-edit').data('can-edit');
  $scope.activities = [];
  $scope.convertBytes = (bytes, decimalPoint) => {
    if(bytes == 0) { return '0 B'; }
    var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  $scope.dateTimeFormat = { 
    alias: 'datetime', 
    inputFormat: "yyyy-mm-dd HH:MM:ss"
  };
  $scope.hasTrackingLinkFlag = (flags, flag) => {
    for (var i = 0; i < flags.length; i++) {
      if (flags[i] == flag) {
        return true;
      }
    }
    return false;
  }
  $scope.resetNewAlerticForm = () => {
    $scope.newLink = {
      type: 'RANDOM',
      expirationType: 'NONE',
      expirationMaxClicks: 0,
      alertType: [{id: 'ALERT_NOTIFICATION', text: ''}],
      expirationDate: '',
      alerteecType: 'URL',
      file: null
    };
    $scope.newAlerteecProgressPercent = 0;
  }
  $scope.resetNewAlerticForm();
  $scope.resetEditAlerticForm = () => {
    $scope.editLink = {
      type: 'RANDOM',
      expirationType: 'NONE',
      expirationMaxClicks: 0,
      alertType: [],
      expirationDate: '',
      alerteecType: 'URL',
      file: null
    };
    $scope.editAlerteecProgressPercent = 0;
  }
  $scope.resetEditAlerticForm();
  $scope.onNewLinkCancel = () => {
    $scope.resetNewAlerticForm();
  }
  $scope.onNewLinkSave = () => {
    $scope.newAlerteecLoading = true;
    $timeout(() => {
      var flags = [];
      flags.push('TRACKING');
      var formData = new FormData();
      if ($scope.newLink.alerteecType == 'FILE') {
        if ($scope.newLink.file) {
          formData.append('file', $scope.newLink.file);
        }
        flags.push('FILE_DOWNLOAD');
      } else {
        flags.push('ABSOLUTE_REDIRECT');
      }
      
      if ($scope.newLink.type == 'CUSTOM') {
        flags.push('CUSTOM');
      }
      
      if ($scope.newLink.expirationType == 'DATE') {
        flags.push('HAS_EXPIRATION_DATE');
      }
      else if ($scope.newLink.expirationType == 'CLICK_LIMIT') {
        flags.push('LIMITED_USE');
      }
      else if ($scope.newLink.expirationType == 'ONE_CLICK') {
        flags.push('ONE_TIME_USE');
      } 
      else if ($scope.newLink.expirationType == 'NONE') {
        flags.push('PERMANENT_USE');
      }
      
      $scope.newLink.alertType.forEach((e) => {
        flags.push(e.id);
      });
      
      formData.append('trackingLink', JSON.stringify({
        id: 0,
        flags: flags,
        key: $scope.newLink.key,
        maxClicks: $scope.newLink.expirationMaxClicks,
        expirationDate: $scope.newLink.expirationDate,
        comment: $scope.newLink.comment,
        target: {
          target: $scope.newLink.target
        }
      }));
      
      $.ajax({
        url: '/api/alertic/',
        type: 'POST',
        xhr: function() {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener('progress', (event) => {
              if (event.lengthComputable) {
                $scope.newAlerteecProgressPercent = Math.ceil((event.loaded / event.total) * 100);
                $scope.$apply();
              }
            }, false);
          }
          return myXhr;
        },
        success: function(result) {
          $scope.alerticNewAlert.open('SUCCESS', result.message, 12000);
          $scope.resetNewAlerticForm();
          $scope.linksDataTable.ajax.reload();
          $scope.newAlerteecLoading = false;
          $scope.$apply();
        },
        data: formData,
        cache: false,
        contentType: false,
        processData: false
      }).fail(function(xhr) {
        if (xhr.responseJSON) {
          $scope.alerticNewAlert.open('DANGER', xhr.responseJSON.message, 12000);
          $scope.newAlerteecLoading = false;
          $scope.$apply();
        }
      });
    }, 500);
  }
  $scope.onEditLinkCancel = () => {
    $scope.resetEditAlerticForm();
  }
  $scope.onEditLinkSave = () => {
    $scope.editAlerteecLoading = true;
    $timeout(() => {
      var flags = [];
      flags.push('TRACKING');
      var formData = new FormData();
      if ($scope.editLink.alerteecType == 'FILE') {
        if ($scope.editLink.file) {
          if ($scope.editLink.file.id == null) {
            formData.append('file', $scope.editLink.file);
          }
        }
        flags.push('FILE_DOWNLOAD');
      } else {
        flags.push('ABSOLUTE_REDIRECT');
      }
      
      if ($scope.editLink.type == 'CUSTOM') {
        flags.push('CUSTOM');
      }
      
      if ($scope.editLink.expirationType == 'DATE') {
        flags.push('HAS_EXPIRATION_DATE');
      }
      else if ($scope.editLink.expirationType == 'CLICK_LIMIT') {
        flags.push('LIMITED_USE');
      }
      else if ($scope.editLink.expirationType == 'ONE_CLICK') {
        flags.push('ONE_TIME_USE');
      } 
      else if ($scope.editLink.expirationType == 'NONE') {
        flags.push('PERMANENT_USE');
      }
      
      $scope.editLink.alertType.forEach((e) => {
        flags.push(e.id);
      });
      
      formData.append('trackingLink', JSON.stringify({
        id: $scope.currentLinkId,
        flags: flags,
        key: $scope.editLink.key,
        maxClicks: $scope.editLink.expirationMaxClicks,
        expirationDate: $scope.editLink.expirationDate,
        comment: $scope.editLink.comment,
        target: {
          target: $scope.editLink.target
        }
      }));
      
      $.ajax({
        url: '/api/alertic/',
        type: 'POST',
        xhr: function() {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener('progress', (event) => {
              if (event.lengthComputable) {
                $scope.editAlerteecProgressPercent = Math.ceil((event.loaded / event.total) * 100);
                $scope.$apply();
              }
            }, false);
          }
          return myXhr;
        },
        success: function(result) {
          $scope.alerticEditAlert.open('SUCCESS', result.message, 12000);
          $scope.resetNewAlerticForm();
          $scope.linksDataTable.ajax.reload();
          $scope.editAlerteecLoading = false;
          $scope.$apply();
        },
        data: formData,
        cache: false,
        contentType: false,
        processData: false
      }).fail(function(xhr) {
        if (xhr.responseJSON) {
          $scope.alerticEditAlert.open('DANGER', xhr.responseJSON.message, 12000);
          $scope.editAlerteecLoading = false;
          $scope.$apply();
        }
      });
    }, 500);
  }
  $scope.onSearchChange = (expression) => {
    $scope.linksDataTable.search(expression).draw();
  }
  $scope.copyToClipboard = (content) => {
    var copyInput = document.getElementById("copyToClipboardInput");
    copyInput.value = content;
    copyInput.select();
    document.execCommand("copy");
  }
  $scope.loadActivity = (linkId, startPage, resultsPerPage) => {
    $scope.activityLoading = true;
    $http({
      url: "/api/tracking/activity/",
      method: "GET",
      params: {
        linkIds: [linkId].join(','),
        startPage: startPage,
        resultsPerPage: resultsPerPage
      }
    }).then((response) => {
      $scope.activities = response.data;
      $scope.activityLoading = false;
    });
  }
  $localization.ready().then(() => {
    $scope.allowEdit = $scope.alerteecCanEdit == 1 ? '' : 'disabled';
    $scope.alertTypes = [
      {id: 'ALERT_NOTIFICATION', text: $localization.get('card.of.user.login-as-alert.notification')},
      {id: 'ALERT_EMAIL', text: $localization.get('card.of.user.login-as-alert.email')},
      {id: 'ALERT_PHONE_SMS', text: $localization.get('card.of.user.login-as-alert.sms')},
      {id: 'ALERT_PHONE_CALL', text: $localization.get('card.of.user.login-as-alert.call')}
    ];
    
    $scope.resetNewAlerticForm();
    $scope.resetEditAlerticForm();
    $scope.linksDataTable = $('.table-alerts').DataTable({
      responsive: true,
      language: {
        url: `/json/datatables/${$localization.getLanguage()}.json`
      },
      ajax: {
        url: "/api/alertic/",
        type: "GET"
      },
      columns: [
        { data: "id" },
        { data: "comment", className: 'text-abbreviate' },
        { data: "key", className: 'td-min-width text-nowrap' },
        { data: "target", className: 'text-abbreviate' },
        { data: "clicks", className: 'd-none d-xl-table-cell' },
        { data: "flags", className: 'd-none d-xl-table-cell' }
      ],
      columnDefs: [{
        targets: 6,
        data: null,
        searchable: false,
        className: "text-right p-0 pt-2 pr-2",
        defaultContent: `
          <div class="text-nowrap">
              <button class="btn btn-sm btn-info btn-copy" data-toggle="tooltip" title="${$localization.get('alerteec.copy-link')}">
                  <i class="fa fa-fw fa-clipboard" aria-hidden="true"></i>
              </button>
              <button class="btn btn-sm btn-primary btn-activity" data-toggle="tooltip" title="${$localization.get('alerteec.activity')}">
                  <i class="fa fa-fw fa-area-chart" aria-hidden="true"></i>
              </button>
              <button class="btn btn-sm btn-danger btn-delete" data-toggle="tooltip" title="${$localization.get('system.delete')}" ${$scope.allowEdit}>
                  <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
              </button>
              <button class="btn btn-sm btn-success btn-edit" data-toggle="tooltip" title="${$localization.get('system.edit')}" ${$scope.allowEdit}>
                  <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>
              </button>
          </div>`
      },{
        render: (data, type, row) => {
          return $scope.hasTrackingLinkFlag(data, 'EXPIRED') ? `<span class="text-danger">${$localization.get('system.yes')}</span>` : `<span class="text-success">${$localization.get('system.no')}</span>`;
        },
        targets: 5
      },{
        render: (data, type, row) => {
          if (data == null) {
            var size = $scope.convertBytes(row.downloadFile.size, 2);
            return `<i><a class="text-abbreviate" target="_blank" href="/files/${row.downloadFile.id}/${row.downloadFile.name}">${row.downloadFile.name}</a></i> <span class="text-success">[${size}]</span>`;
          }
          return `<a target="_blank" class="text-abbreviate" href="${data.target}">${data.target}</a>`;
        },
        targets: 3
      },{
        render: (data, type, row) => {
          return `<span style='letter-spacing: -1px;'>${window.location.protocol}//${window.location.host}/</span><strong>${data}</strong>`;
        },
        targets: 2
      }],
      sDom: 't<"card-footer border-top card-footer-pagination"<"row"<"col-6"i><"col-6"p>>>'
    });
    $scope.linksDataTable.on('draw', function() {
      $('[data-toggle="tooltip"]').tooltip(); 
    });
    $('.table-alerts tbody').on('click', 'button.btn-activity', function(event) {
      var row = $scope.linksDataTable.row($(this).parents('tr')).data();
      $('#activity-dialog').modal('show');
      $scope.currentActivityLinkId = row.id;
      $scope.activityLink = `${window.location.protocol}//${window.location.host}/${row.key}`;
      $scope.loadActivity(row.id, 0, $scope.activitiesPerPage);
      $scope.$apply();
    });
    $('.table-alerts tbody').on('click', 'button.btn-delete', function(event) {
      var row = $scope.linksDataTable.row($(this).parents('tr')).data();
      var messages = {
        title: $localization.get('alerteec.delete.confirmation-title'),
        message: $localization.get('alerteec.delete.confirmation-message'),
        cancelText: $localization.get('system.no'),
        confirmText: $localization.get('system.yes')
      };
      $scope.confirmDialog.open(messages).then((response) => {
        $http({
          url: "/api/alertic/",
          method: "DELETE",
          params: {
            linkId: row.id
          }
        }).then((response) => {
          $scope.alerticAlert.open('SUCCESS', response.data.message, 8000);
          $scope.linksDataTable.ajax.reload();
        });
      });
      $scope.$apply();
    });
    $('.table-alerts tbody').on('click', 'button.btn-edit', function(event) {
      var row = $scope.linksDataTable.row($(this).parents('tr')).data();

      $scope.editLinkName = `${window.location.protocol}//${window.location.host}/${row.key}`;
      $scope.currentLinkId = row.id;

      $scope.editLink.type = $scope.hasTrackingLinkFlag(row.flags, 'CUSTOM') ? 'CUSTOM' : 'RANDOM';
      $scope.editLink.key = row.key;
      $scope.editLink.expirationDate = row.expirationDate;
      $scope.editLink.expirationMaxClicks = Math.max(row.maxClicks, 1);
      $scope.editLink.comment = row.comment;
      
      if ($scope.hasTrackingLinkFlag(row.flags, 'ABSOLUTE_REDIRECT')) {
        $scope.editLink.alerteecType = 'URL';
        $scope.editLink.target = row.target.target;
      } else if ($scope.hasTrackingLinkFlag(row.flags, 'FILE_DOWNLOAD')) {
        $scope.editLink.alerteecType = 'FILE';
        $scope.editLink.file = {
          name: row.downloadFile.name,
          size: row.downloadFile.size,
          id: row.downloadFile.id
        }
      }
  
      if ($scope.hasTrackingLinkFlag(row.flags, 'HAS_EXPIRATION_DATE')) {
        $scope.editLink.expirationType = 'DATE';
      } else if ($scope.hasTrackingLinkFlag(row.flags, 'LIMITED_USE')) {
        $scope.editLink.expirationType = 'CLICK_LIMIT';
      } else if ($scope.hasTrackingLinkFlag(row.flags, 'ONE_TIME_USE')) {
        $scope.editLink.expirationType = 'ONE_CLICK';
      } else if ($scope.hasTrackingLinkFlag(row.flags, 'PERMANENT_USE')) {
        $scope.editLink.expirationType = 'NONE';
      }
      
      $scope.showEditAlerts = false;
      $scope.$apply();
      
      ['ALERT_NOTIFICATION', 'ALERT_EMAIL', 'ALERT_PHONE_SMS', 'ALERT_PHONE_CALL'].forEach((e) => {
        if ($scope.hasTrackingLinkFlag(row.flags, e)) {
          $scope.editLink.alertType.push({
            id: e, text: ''
          });
        }
      });

      $scope.showEditAlerts = true;
      $scope.$apply();
      $('#edit-link-dialog').modal('show');
    });
    $('.table-alerts tbody').on('click', 'button.btn-copy', function(event) {
      var row = $scope.linksDataTable.row($(this).parents('tr')).data();
      $scope.copyToClipboard(`${window.location.protocol}//${window.location.host}/${row.key}`);
      $scope.alerticAlert.open('SUCCESS', $localization.get('alerteec.link-copied'), 6000);
      $scope.$apply();
    });
    $('.file-drop-zone').on('dragover dragenter', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    $('.new-alertic-drop-zone').on('drop', (event) => {
      if (event.originalEvent.dataTransfer) {
        if (event.originalEvent.dataTransfer.files.length) {
          event.preventDefault();
          event.stopPropagation();
          var file = event.originalEvent.dataTransfer.files[0];
          if ($('file-config').data('max-file-size') < file.size) {
            $scope.alerticNewAlert.open('WARNING', $localization.get('alerteec.file-too-big'), 12000);
            $scope.$apply();
            return;
          }
          $scope.newLink.file = file;
          $scope.$apply();
        }
      }
    });
    $('.edit-alertic-drop-zone').on('drop', (event) => {
      if (event.originalEvent.dataTransfer) {
        if (event.originalEvent.dataTransfer.files.length) {
          event.preventDefault();
          event.stopPropagation();
          var file = event.originalEvent.dataTransfer.files[0];
          if ($('file-config').data('max-file-size') < file.size) {
            $scope.alerticEditAlert.open('WARNING', $localization.get('alerteec.file-too-big'), 12000);
            $scope.$apply();
            return;
          }
          $scope.editLink.file = file;
          $scope.$apply();
        }
      }
    });
  });
}]);