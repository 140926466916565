$(() => {
  var alertCreditials = $('.alert-save-creditials').bootstrapAlert();
  var alertAboutMe = $('.alert-save-about-me').bootstrapAlert();
  var avatarData = null;
  var stopPropagationFn = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }
  $('.about-me-skills').select2({
    tags: true
  });
  $('.btn-save-creditials').on('click', () => {
    $.ajax({
      method: "POST",
      url: "/api/profile/creditials/save/",
      data: $('.form-creditials').serialize()
    }).done((result) => {
      alertCreditials.display('alert-success', result.message, 12000);
    }).fail((xhr) => {
      alertCreditials.display('alert-danger', xhr.responseJSON.message, 12000);
    });
  });
  $('.btn-about-me').on('click', () => {
    var skills = [];
    var adapter = $('.about-me-skills').data().select2.dataAdapter;
    $('.about-me-skills').select2('data').forEach((element) => {
      skills.push(element.text);
    });
    var profilePayload = {
      location: $('.profile-location').val(),
      position: $('.profile-position').val(),
      education: $('.profile-education').val(),
      biography: $('.profile-biography').val(),
      skills: skills,
      avatarData: avatarData
    };
    $.ajax({
      method: "POST",
      url: "/api/profile/aboutme/save/",
      data: profilePayload
    }).done((result) => {
      alertAboutMe.display('alert-success', result.message, 12000);
    }).fail((xhr) => {
      alertAboutMe.display('alert-danger', xhr.responseJSON.message, 12000);
    });
  });
  $('.avatar-drop-zone').on('dragover', stopPropagationFn);
  $('.avatar-drop-zone').on('dragenter', stopPropagationFn);
  $('.avatar-drop-zone').on('drop', (e) => {
    if (e.originalEvent.dataTransfer) {
      if (e.originalEvent.dataTransfer.files.length) {
        e.preventDefault();
        e.stopPropagation();
        var accept = ["image/png", "image/jpeg", "image/gif"];
        if (accept.indexOf(e.originalEvent.dataTransfer.files[0].type) > -1) {
          var reader = new FileReader();
          reader.readAsDataURL(e.originalEvent.dataTransfer.files[0]);
          reader.addEventListener("load", () => {
            $('.profile-user-img').attr('src', reader.result);
            avatarData = reader.result;
          }, false);
        }
      }
    }
  });
});