/**
 * 
 * Simple bootstrap confirm dialog plugin
 * @author Jan Lošťák
 * @version 1.0
 * 
 */
$.fn.bootstrapConfirm = function() {
	var c = this;
	
    /**
     * Open dialog
     * @param object
     */
	var openObj = function(object) {
		open(object.title, object.message, object.confirmFn, object.dismissFn);
	}
    
    /**
     * Open dialog
     * @param title
     * @param message
     */
    var open = function(cTitle, cMessage, cCallable, dCallable) {
		c.find('.modal-title').html(cTitle);
		c.find('.modal-body').html(cMessage);

		c.find('.modal-confirm').unbind('click');
		c.find('.modal-confirm').click(function(event) 
		{
			if (cCallable)
				cCallable(this);
			
			c.modal('hide'); 
		});
		
		c.find('.modal-dismiss').unbind('click');
		c.find('.modal-dismiss').click(function(event) 
		{
			if (dCallable)
				dCallable(this);
			
			c.modal('hide'); 
		});
		
		c.modal('show'); 
    }

    // Return public API
    return {
    	open: open,
    	openObj: openObj
    };
}