angular.module('login', []).controller('LoginController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.showEmailForm = true;
  $scope.showPasswordForm = false;
  $scope.showFirstLoginForm = false;
  $scope.showProgress = false;
  $scope.firstLoginDone = false;
  $scope.firstLogin = false;
  $scope.rememberMe = false;
  $scope.userName = '';
  $scope.password = '';
  $scope.newPassword1 = '';
  $scope.newPassword2 = '';
  $scope.targetUrl = '';
  $scope.validateUserName = () => {
    $scope.showProgress = true;
    $http({
      url: "/api/auth/validate/username/",
      method: "POST",
      params: {
        userName: $scope.userName,
      }
    }).then((response) => {
      $timeout(() => {
        $scope.checkFirstLogin();
        $scope.showProgress = false;
      }, 1000);
    }, (response) => {
      $timeout(() => {
        $scope.loginBoxMessage = response.data.message;
        $scope.showProgress = false;
      }, 1000);
    });
  }
  $scope.checkFirstLogin = () => {
    $http({
      url: "/api/auth/has-first-login/",
      method: "POST",
      params: {
        "username": $scope.userName,
      }
    }).then((response) => {
      if (response.data) {
        $scope.loginBoxMessage = $localization.get('auth.login.first.default.msg');
        $scope.showFirstLoginForm = true;
        $scope.showEmailForm = false;
        $scope.firstLogin = true;
      } else {
        $scope.loginBoxMessage = $localization.get('auth.login.please.enter.password');
        $scope.showPasswordForm = true;
        $scope.showEmailForm = false;
      }
    });
  }
  $scope.loginUser = () => {
    if (!$scope.firstLogin) {
      $scope.showProgress = true;
    }
    $http({
      url: "/api/auth/login/",
      method: "POST",
      params: {
        "username": $scope.userName,
        "password": $scope.password,
        "remember-me": $scope.rememberMe
      }
    }).then((response) => {
      if (!$scope.firstLogin) {
        $scope.loginBoxMessage = $localization.get('auth.login.success');
        $timeout(() => {
          window.location.assign(response.data.redirectUrl);
        }, 2000);
      } else {
        $timeout(() => {
          $scope.loginBoxMessage = $localization.get('auth.first.login.success');
          $scope.showProgress = false;
          $scope.firstLoginDone = true;
          $scope.targetUrl = response.data.redirectUrl;
        }, 2000);
      }
    }, (response) => {
      $timeout(() => {
        $scope.loginBoxMessage = response.data.message;
        $scope.showProgress = false;
      }, 1000);
    });
  };
  $scope.finishFirstLogin = () => {
    $scope.showProgress = true;
    if ($scope.newPassword1 != $scope.newPassword2) {
      $timeout(() => {
        $scope.loginBoxMessage = $localization.get('auth.register.password.notmatch');
        $scope.showProgress = false;
      }, 1000);
    } else if ($scope.newPassword1.length == 0 || $scope.newPassword2.length == 0) {
      $timeout(() => {
        $scope.loginBoxMessage = $localization.get('auth.login.please.enter.password');
        $scope.showProgress = false;
      }, 1000);
    } else {
      $scope.password = $scope.newPassword1;
      $scope.loginUser(true);
    }
  }
  $localization.ready().then(() => {
    $scope.loginBoxMessage = $localization.get('auth.login.default.msg');
  });
}]);