angular.module('roles', ['twbs']).controller('RolesController', ['$scope', '$http', '$localization', ($scope, $http, $localization) => {
  $scope.confirmDialog = {};
  $scope.loadPermissions = () => {
    $http({
      url: "/api/role/permissions/groups/",
      method: "GET"
    }).then((response) => {
      response.data.sort((a, b) => {
        return a.sort - b.sort;
      });
      response.data.forEach((group) => {
        group.permissions.forEach((perm) => {
          perm.isChecked = false;
        });
        group.permissions.sort((a, b) => {
          return a.sort - b.sort;
        });
      });
      $scope.permissions = response.data;
      $scope.resetEditForm();
    });
  }
  $scope.loadRoles = () => {
    $http({
      url: "/api/roles/",
      method: "GET"
    }).then((response) => {
      $scope.roles = response.data;
    });
  }
  $scope.editExistingRole = (role) => {
    $scope.permissions.forEach((group) => {
      group.permissions.forEach((permission1) => {
        permission1.isChecked = false;
        role.permissions.forEach((permission2) => {
          if (permission1.id == permission2.id)
            permission1.isChecked = true;
        });
      });
    });
    $scope.roleEditDisabled = false;
    $scope.editRole.role = role;
  }
  $scope.deleteRole = (role) => {
    var messages = {
      title: $localization.get('admin.role.delete.confirm.title'),
      message: $localization.get('admin.role.delete.confirm.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      $http({
        url: "/api/role/",
        method: "DELETE",
        params: {
          roleId: role.id,
        }
      }).then((response) => {
        $scope.resetEditForm();
        $scope.alertRoles.open('SUCCESS', response.data.message, 12000);
        $scope.loadRoles();
      }, (response) => {
        $scope.alertRoles.open('DANGER', response.data.message, 12000);
      });
    });
  }
  $scope.createRole = (role) => {
    $http({
      url: "/api/role/",
      method: "POST",
      data: role
    }).then((response) => {
      $scope.alertRoles.open('SUCCESS', response.data.message, 12000);
      $scope.loadRoles();
    }, (response) => {
      if (response.data.errors != null) {
        response.data.errors.forEach((error) => {
          $scope.newRole.valid[error.field].isValid = false;
          $scope.newRole.valid[error.field].feedback = error.defaultMessage;
        });
      } else $scope.alertRoles.open('DANGER', response.data.message, 12000);
    });
  }
  $scope.saveRole = (role) => {
    role.permissions = [];
    $scope.permissions.forEach((group) => {
      group.permissions.forEach((permission) => {
        if (permission.isChecked)
          role.permissions.push(permission);
      });
    });
    $http({
      url: "/api/role/",
      method: "POST",
      data: role
    }).then((response) => {
      $scope.alertRoles.open('SUCCESS', response.data.message, 12000);
      $scope.resetEditForm();
    }, (response) => {
      if (response.data.errors != null) {
        response.data.errors.forEach((error) => {
          $scope.editRole.valid[error.field].isValid = false;
          $scope.editRole.valid[error.field].feedback = error.defaultMessage;
        });
      } else $scope.alertRoles.open('DANGER', response.data.message, 12000);
    });
  }
  $scope.editRoleCancel = () => {
    $scope.resetEditForm();
  }
  $scope.resetEditForm = () => {
    $scope.permissions.forEach((group) => {
      group.permissions.forEach((permission) => {
        permission.isChecked = false;
      });
    });
    $scope.editRole = {
      role: {
        name: '',
        description: '',
        defaultRole: ''
      },
      valid: {
        name: {
          isValid: true,
          feedback: ''
        },
        description: {
          isValid: true,
          feedback: ''
        }
      }
    };
    $scope.roleEditDisabled = true;
  }
  $scope.resetNewRoleForm = () => {
    $scope.newRole = {
      role: {
        name: '',
        description: '',
        defaultRole: 'NO'
      },
      valid: {
        name: {
          isValid: true,
          feedback: ''
        },
        description: {
          isValid: true,
          feedback: ''
        }
      }
    };
  }
  $localization.ready().then(() => {
    $scope.loadPermissions();
    $scope.loadRoles();
    $scope.resetNewRoleForm();
  });
}]);