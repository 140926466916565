angular.module('menu.notifications', []).controller('NotificationController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  var SMSG_PUSH_NOTIFICATION = 2;
  var CMSG_FETCH_NOTIFICATIONS_GROUPED = 3;
  var SMSG_FETCH_NOTIFICATIONS_GROUPED_RESPONSE = 4;
  var icons = {
      FRIEND_REQUEST: 'fa-users',
      NEW_EMAIL: 'fa-envelope',
      WARNING: 'fa-exclamation-triangle',
      CLICK: 'fa-hand-pointer-o'
  }
  $scope.notificationsOverview = [];
  $scope.notificationsCount = 0;
  $scope.starClient = function() {
    var proto = window.location.protocol == "http:" ? "ws:" : "wss:";
    $scope.socket = new WebSocket(proto + "//" + window.location.host + "/ws/notification/");
    $scope.socket.onclose = function(event) {
      $timeout(() => {
        $scope.starClient();
      }, 1000);
    };
    $scope.socket.onmessage = function(event) {
      var message = JSON.parse(event.data);
      message.json = JSON.parse(message.json);

      switch(message.opcode) {
        case SMSG_PUSH_NOTIFICATION:
          $scope.handlePushNotification(message);
          break;
        case SMSG_FETCH_NOTIFICATIONS_GROUPED_RESPONSE:
          $scope.handleGroupedNotifications(message);
          break;
      }
    };
    $scope.socket.onopen = function(event) {
      $scope.fetchGroupedNotifications();
    };
    $scope.handleGroupedNotifications = (message) => {
      $scope.notificationsOverview = message.json;
      var counter = 0;
      for (var prop in message.json) {
        counter += message.json[prop].count;
      }
      $scope.notificationsCount = counter;
      $scope.$apply();
    }
    $scope.handlePushNotification = (message) => {
      if (message.json.type == 'CLICK') {
        var sfx = new Audio(`/sound/alert_17.mp3`);
        sfx.volume = 0.1;
        sfx.play();
      } else {
        var sfx = new Audio(`/sound/pop_01.mp3`);
        sfx.volume = 0.1;
        sfx.play();
      }
      var icon = icons[message.json.type] == null ? 'fa-bell-o' : icons[message.json.type];
      $scope.$emit('receivedPushNotification', message.json);
      $scope.fetchGroupedNotifications();
      $.notify({
        message: message.json.message
      },{
        type: 'success',
        z_index: 9999,
        allow_dismiss: false,
        delay: 8000,
        width: 'auto;',
        placement: {
          from: 'bottom',
          align: 'right'
        },
        template: '<div data-notify="container" style="opacity: 0.9" class="text-nowrap shadow-md alert alert-{0}" role="alert">' +
                    '<span data-notify="message d-block"><i class="fa ' + icon + ' mr-2" aria-hidden="true"></i> {2}</span>' +
                  '</div>'
      });
    }
    $scope.fetchGroupedNotifications = () => {
      $scope.socket.send(JSON.stringify({ opcode: CMSG_FETCH_NOTIFICATIONS_GROUPED, json: "" }));
    }
  };
  $scope.starClient();
  $localization.ready().then(() => {});
}]);