angular.module('unlock.view-only', []).controller('UnlockUserViewOnlyController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.password = '';
  $scope.unlockProgress = false;
  $scope.onLogin = () => {
    $scope.unlockProgress = true;
    $timeout(() => {
      $http({
        url: "/api/auth/unlock/view-only/",
        method: "POST",
        params: {
          password: $scope.password
        }
      }).then((response) => {
        $timeout(() => {
          window.location.reload();
        }, 1000);
        $scope.alertUnlockViewOnly.open('SUCCESS', response.data.message, 12000);
      }, (response) => {
        $scope.alertUnlockViewOnly.open('DANGER', response.data.message, 12000);
        $scope.unlockProgress = false;
      });
    }, 1000);
  }
  $localization.ready().then(() => { });
}]);