// Error reporting to server
window.onerror = (msg, url, lineNo, columnNo, error) => {
  $.ajax({
    method: "POST", 
    url: "/api/report/error/", 
    contentType : "application/json", 
    dataType: 'json',
    data: JSON.stringify({ 
      url: (typeof url === 'string' || url instanceof String) ? url : JSON.stringify(url),
      msg: (typeof msg === 'string' || msg instanceof String) ? msg : JSON.stringify(msg),
      lineNo: lineNo,
      columnNo: columnNo,
      error: (typeof error === 'string' || error instanceof String) ? error : JSON.stringify(error)
    })
  });
  return false;
};

// Angular application
var app = angular.module('isaacApp', [
  'localization','dashboard','user', 'tasks',
  'roles','degrees','login','register',
  'reset','cards','cards.packages',
  'cards.edit','menu.notifications',
  'user.notifications','unlock.view-only',
  'alertic','vocabulary', 'vocabulary.words'
]);

app.config(['$qProvider', ($qProvider) => {
  $qProvider.errorOnUnhandledRejections(false);
}]);

//Convert numeric option values in select
app.directive('convertToNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(val) {
        return val != null ? parseInt(val, 10) : null;
      });
      ngModel.$formatters.push(function(val) {
        return val != null ? '' + val : null;
      });
    }
  };
});

//Time from now filter
app.filter('timeFromNow', () => {
  return (timeMilis, format) => {
    return moment(timeMilis, format).fromNow();
  };
});

//Convert bytes to readable form filter
app.filter('bytesToReadableSize', () => {
  return (bytes, decimalPoint) => {
      if(bytes == 0) { return '0 B'; }
      var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
});

$(() => {
  // Side menu toggle
  $('.pushmenu-collapse').on('click', function() {
    $.ajax({method: "POST", url: "/api/user/options/toggle/", data: { option: "side-menu-collapsed" }});
  });
  
  // Hide sidebar menu if display is too small
  var checkScreenWidth = () => {
	if (window.innerWidth < 992) {
      $('.sidebar-mini').removeClass('sidebar-open');
      $('.sidebar-mini').removeClass('sidebar-collapse');
      $('.sidebar-mini').addClass('sidebar-collapse');
	}
  }
  
  $(window).on('resize', checkScreenWidth);
  checkScreenWidth();

  // Side menu toggle tree node
  $('.toggle-collapsed').on('click', function() {
    var data = {
      menuItemId: $(this).attr('data-menu-item-id')
    };
    $.ajax({method: "POST", url: "/api/user/options/toggle-side-menu-item-collapsed/", data: data});
  });
});