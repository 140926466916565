angular.module('register', []).controller('RegisterController', ['$scope', '$http', '$timeout', '$localization', ($scope, $http, $timeout, $localization) => {
  $scope.showProgress = false;
  $scope.showForm = true;
  $scope.username = '';
  $scope.useremail = '';
  $scope.password1 = '';
  $scope.password2 = '';
  $scope.register = () => {
    $scope.showProgress = true;
    $http({
      url: "/api/auth/register/",
      method: "POST",
      params: {
        username: $scope.username,
        useremail: $scope.useremail,
        password1: $scope.password1,
        password2: $scope.password2,
      }
    }).then((response) => {
      $timeout(() => {
        $scope.loginBoxMessage = response.data.message;
        $scope.showProgress = false;
        $scope.showForm = false;
      }, 1000);
    }, (response) => {
      $timeout(() => {
        $scope.loginBoxMessage = response.data.message;
        $scope.showProgress = false;
      }, 1000);
    });
  };
  $localization.ready().then(() => {
    $scope.loginBoxMessage = $localization.get('auth.register.default.msg');
  });
}]);