angular.module('vocabulary', ['twbs']).controller('VocabularyController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.vocabularyAlert = {};
  $scope.newVocabularyLoading = false;
  $scope.vocabularyCanEdit = $('vocabulary-can-edit').data('can-edit');
  $scope.vocabularyCanDelete = $('vocabulary-can-delete').data('can-edit');
  $scope.vocabularyCanView = $('vocabulary-can-view').data('can-edit');
  $scope.settingsOpts = [{id: '', test: ''}];
  $scope.editVocabularyID = 0;
  $scope.editVocabularyName = '';
  $scope.editVocabularyLoading = false;
  $scope.editVocabulary = { 
     settings: []
  }
  $scope.newVocabularySettings = [];
  $http({
    url: "/api/locale/language/codes/",
    method: "GET"
  }).then((response) => {
    var options = [];
    angular.forEach(response.data, (name, code) => {
      options.push({ id: code, text: name });
    });
    $scope.languageCodes = options;
  });
  $scope.hasFlag = (flags, flag) => {
    for (var i = 0; i < flags.length; i++) {
      if (flags[i] == flag) {
        return true;
      }
    }
    return false;
  }
  $scope.onSearchChange = (expression) => {
    $scope.vocabularyDataTable.search(expression).draw();
  }
  $scope.onEditVocabularyCancel = () => {
    $scope.editVocabularyLoading = false;
    $scope.editVocabulary.settings = [];
    $scope.editVocabularyName = '';
    $scope.editVocabularySourceLang = null;
    $scope.editVocabularyTargetLang = null;
    $scope.vocabularyEditAlert.close();
  }
  $scope.onEditVocabularySave = () => {
    $scope.editVocabularyLoading = true;
    var flags = [];
    $scope.editVocabulary.settings.forEach((e) => {
      flags.push(e.id);
    });
    $timeout(() => {
      $http({
        url: "/api/vocabulary/",
        method: "POST",
        data: {
          id: $scope.editVocabularyID,
          flags: flags,
          name: $scope.editVocabularyName,
          sourceLang: $scope.editVocabularySourceLang,
          targetLang: $scope.editVocabularyTargetLang
        }
      }).then((response) => {
        $scope.vocabularyEditAlert.open('SUCCESS', response.data.message, 8000);
        $scope.editVocabularyLoading = false;
        $scope.vocabularyDataTable.ajax.reload();
      }, (response) => {
        $scope.vocabularyEditAlert.open('DANGER', response.data.message, 8000);
        $scope.editVocabularyLoading = false;
      });
    }, 1000);
  }
  $scope.onNewVocabularyCancel = () => {
    $scope.newVocabularyLoading = false;
    $scope.newVocabularySettings = [];
    $scope.newVocabularyName = '';
    $scope.newVocabularySourceLang = null;
    $scope.newVocabularyTargetLang = null;
    $scope.vocabularyNewAlert.close();
  }
  $scope.onNewVocabularySave = () => {
    $scope.newVocabularyLoading = true;
    $timeout(() => {
      var flags = [];
      $scope.newVocabularySettings.forEach((e) => {
        flags.push(e.id);
      });
      $http({
        url: "/api/vocabulary/",
        method: "POST",
        data: {
          id: 0,
          flags: flags,
          name: $scope.newVocabularyName,
          sourceLang: $scope.newVocabularySourceLang,
          targetLang: $scope.newVocabularyTargetLang
        }
      }).then((response) => {
        $scope.vocabularyNewAlert.open('SUCCESS', response.data.message, 8000);
        $scope.newVocabularyLoading = false;
        $scope.vocabularyDataTable.ajax.reload();
        
        $scope.newVocabularySettings = [];
        $scope.newVocabularyName = '';
        $scope.newVocabularySourceLang = null;
        $scope.newVocabularyTargetLang = null;
      }, (response) => {
        $scope.vocabularyNewAlert.open('DANGER', response.data.message, 8000);
        $scope.newVocabularyLoading = false;
      });
    }, 1000);
  }
  $localization.ready().then(() => {
    $scope.settingsOpts = [
      { id: 'IS_GLOBAL', text: $localization.get('vocabulary.settings.is-global')},
      { id: 'IS_PRIVATE', text: $localization.get('vocabulary.settings.is-private')},
      { id: 'HAS_FREQUENCY', text: $localization.get('vocabulary.settings.has-frequency')},
      { id: 'HAS_TOKENS', text: $localization.get('vocabulary.settings.has-tokens')},
      { id: 'HAS_PAV', text: $localization.get('vocabulary.settings.has-pav')},
      { id: 'HAS_IPA', text: $localization.get('vocabulary.settings.has-ipa')},
      { id: 'HAS_OTHER_PHONETIC', text: $localization.get('vocabulary.settings.has-other')}
    ];
    $scope.newVocabularySettings = [];
    $scope.editVocabulary.settings = [];
    $scope.allowEdit = $scope.vocabularyCanEdit == 1 ? '' : 'disabled';
    $scope.allowDelete = $scope.vocabularyCanDelete == 1 ? '' : 'disabled';
    $scope.allowView = $scope.vocabularyCanView == 1 ? '' : 'disabled';
    $scope.vocabularyDataTable = $('.table-vocabulary').DataTable({
      responsive: true,
      language: {
        url: `/json/datatables/${$localization.getLanguage()}.json`
      },
      ajax: {
        url: "/api/vocabulary/",
        type: "GET"
      },
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "flags",  },
        { data: "sourceLanguageName", },
        { data: "targetLanguageName", }
      ],
      columnDefs: [{
        render: (data, type, row) => {
          var types = [];
          if ($scope.hasFlag(data, 'IS_GLOBAL')) {
            types.push(`<small class="badge badge-info mr-1">${$localization.get('vocabulary.settings.is-global')}</small>`);
          }
          if ($scope.hasFlag(data, 'IS_PRIVATE')) {
            types.push(`<small class="badge badge-warning mr-1">${$localization.get('vocabulary.settings.is-private')}</small>`);
          }
          if ($scope.hasFlag(data, 'HAS_FREQUENCY')) {
            types.push(`<small class="badge badge-primary mr-1">${$localization.get('vocabulary.settings.has-frequency')}</small>`);
          }
          if ($scope.hasFlag(data, 'HAS_TOKENS')) {
            types.push(`<small class="badge badge-light border mr-1">${$localization.get('vocabulary.settings.has-tokens')}</small>`);
          }
          if ($scope.hasFlag(data, 'HAS_PAV')) {
            types.push(`<small class="badge badge-dark mr-1">${$localization.get('vocabulary.settings.has-pav')}</small>`);
          }
          if ($scope.hasFlag(data, 'HAS_IPA')) {
            types.push(`<small class="badge badge-dark border mr-1">${$localization.get('vocabulary.settings.has-ipa')}</small>`);
          }
          if ($scope.hasFlag(data, 'HAS_OTHER_PHONETIC')) {
            types.push(`<small class="badge badge-dark border mr-1">${$localization.get('vocabulary.settings.has-other')}</small>`);
          }
          return types.join('');
        },
        targets: 2
      },{
        targets: 5,
        data: null,
        searchable: false,
        className: "text-right p-0 pt-2 pr-2",
        defaultContent: `
          <div class="text-nowrap">
              <button class="btn btn-sm btn-info btn-open" data-toggle="tooltip" title="${$localization.get('system.open')}" ${$scope.allowView}>
                  <i class="fa fa-fw fa-folder-open-o" aria-hidden="true"></i>
              </button>
              <button class="btn btn-sm btn-danger btn-delete" data-toggle="tooltip" title="${$localization.get('system.delete')}" ${$scope.allowDelete}>
                  <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
              </button>
              <button class="btn btn-sm btn-success btn-edit" data-toggle="tooltip" title="${$localization.get('system.edit')}" ${$scope.allowEdit}>
                  <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>
              </button>
          </div>`
      }],
      sDom: 't<"card-footer border-top card-footer-pagination"<"row"<"col-6"i><"col-6"p>>>'
    });
    $('.table-vocabulary tbody').on('click', 'button.btn-delete', function(event) {
      var row = $scope.vocabularyDataTable.row($(this).parents('tr')).data();
      var messages = {
        title: $localization.get('vocabulary.delete.confirmation-title'),
        message: $localization.get('vocabulary.delete.confirmation-message'),
        cancelText: $localization.get('system.no'),
        confirmText: $localization.get('system.yes')
      };
      $scope.confirmDialog.open(messages).then((response) => {
        $http({
          url: "/api/vocabulary/",
          method: "DELETE",
          params: {
            vocabularyId: row.id
          }
        }).then((response) => {
          $scope.vocabularyAlert.open('SUCCESS', response.data.message, 8000);
          $scope.vocabularyDataTable.ajax.reload();
        }, (response) => {
          $scope.vocabularyAlert.open('DANGER', response.data.message, 12000);
        });
      });
      $scope.$apply();
    });
    $('.table-vocabulary tbody').on('click', 'button.btn-edit', function(event) {
      var row = $scope.vocabularyDataTable.row($(this).parents('tr')).data();
      $('#edit-vocabulary-dialog').modal('show');
      row.flags.forEach((e) => {
        $scope.editVocabulary.settings.push({ id: e, text: '' });
      });
      $scope.showEditSettings = false;
      $scope.$apply();
      $scope.showEditSettings = true;
      $scope.$apply();
      $scope.editVocabularyID = row.id;
      $scope.editVocabularyName = row.name;
      $scope.editVocabularySourceLang = row.sourceLang;
      $scope.editVocabularyTargetLang = row.targetLang;
      $scope.$apply();
    });
    $('.table-vocabulary tbody').on('click', 'button.btn-open', function(event) {
      var row = $scope.vocabularyDataTable.row($(this).parents('tr')).data();
      window.location.href = `/vocabulary/${row.id}/`;
    });
    $scope.vocabularyDataTable.on('draw', function() {
      $('[data-toggle="tooltip"]').tooltip(); 
    });
  });
}]);