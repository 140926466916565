angular.module('dashboard', ['widgets']).controller('DashboardController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.dashboard = {
      grids: []
  };
  $scope.addSlotObj = null;
  $scope.loadDashboard = () => {
    $http({
      url: "/api/dashboard/",
      method: "GET"
    }).then((response) => {
      $scope.dashboard = response.data;
    });
  }
  $scope.deleteWidget = (slot) => {
    var messages = {
      title: $localization.get('widget.delete-confirm-title'),
      message: $localization.get('widget.delete-confirm-message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      slot.widgetId = null;
      slot.widget = null;
      $scope.saveDashboard();
    });
  }
  $scope.deleteRow = (dashboard, grid) => {
    var messages = {
      title: $localization.get('widget.delete-row-confirm-title'),
      message: $localization.get('widget.delete-row-confirm-message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      dashboard.grids = dashboard.grids.filter((element) => {
        return element.$$hashKey != grid.$$hashKey;
      });
      $scope.saveDashboard();
    });
  }
  $scope.onWidgedSelected = () => {
    var widgetId = $('.widget-select').val();
    $scope.addSlotObj.widgetId = widgetId;
    $scope.saveDashboard();
  }
  $scope.addWidget = (slot) => {
    $scope.addSlotObj = slot;
  }
  $scope.createGrid = (size) => {
    var slots = [];
    for (var i = 0; i < size; i++) {
      slots.push({
        id: null,
        gridId: null,
        sort: i,
        widget: null,
        widgetId: null
      });
    }
    if ($scope.dashboard.length == 0) {
      $scope.dashboard = {
          grids: [],
          template: false,
          name: 'Custom'
      };
    }
    console.log($scope.dashboard);
    $scope.dashboard.grids.push({
      id: null,
      dashboardId: null,
      size: size,
      sort: $scope.dashboard.grids.length,
      slots: slots
    });
    $scope.saveDashboard();
  }
  $scope.saveDashboard = () => {
    $http({
      url: "/api/dashboard/",
      method: "POST",
      data: $scope.dashboard
    }).then((response) => {
      $scope.dashboard = response.data;
    });
  }
  $localization.ready().then(() => {
    $scope.loadDashboard();
  });
}]);