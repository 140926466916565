const module = angular.module('widgets', []);

module.directive('userCount', ['$timeout', '$localization', '$http', ($interval, $localization, $http) => {
  var timer;
  return {
    restrict: 'E',
    scope: {
      'options': '='
    },
    link: (scope, elem, attrs) => {
      scope.count = 0;
      scope.loadUsersCount = () => {
        $http({
          url: "/api/dashboard/users-count/",
          method: "GET"
        }).then((response) => {
          scope.count = response.data;
        });
      }
      $interval(() => {
        scope.loadUsersCount();
      }, Math.floor((Math.random() * 5000) + 5000));
      scope.loadUsersCount();
    },
    template: `
       <div class="info-box" style="margin-bottom: 0 !important;">
           <span class="info-box-icon bg-warning elevation-1"><i class="fa fa-users"></i></span>
           <div class="info-box-content">
               <span class="info-box-text">${$localization.get('widget.users')}</span>
               <span class="info-box-number">{{ count | number : fractionSize }}</span>
           </div>
       </div>`
  };
}]);

module.directive('trackingLinksClicks', ['$timeout', '$localization', '$http', ($interval, $localization, $http) => {
  var timer;
  return {
    restrict: 'E',
    scope: {
      'options': '='
    },
    link: (scope, elem, attrs) => {
      scope.count = 0;
      scope.load = () => {
        $http({
          url: "/api/dashboard/tracking-links-click-count/",
          method: "GET"
        }).then((response) => {
          scope.count = response.data;
        });
      }
      $interval(() => {
        scope.load();
      }, Math.floor((Math.random() * 5000) + 5000));
      scope.load();
    },
    template: `
       <div class="info-box" style="margin-bottom: 0 !important;">
           <span class="info-box-icon bg-coral elevation-1"><i class="fa fa-link"></i></span>
           <div class="info-box-content">
               <span class="info-box-text">${$localization.get('widget.tracking-links-clicks')}</span>
               <span class="info-box-number">{{ count | number : fractionSize }}</span>
           </div>
       </div>`
  };
}]);

module.directive('cardsCount', ['$timeout', '$localization', '$http', ($interval, $localization, $http) => {
  var timer;
  return {
    restrict: 'E',
    scope: {
      'options': '='
    },
    link: (scope, elem, attrs) => {
      scope.count = 0;
      scope.load = () => {
        $http({
          url: "/api/dashboard/cards-count/",
          method: "GET"
        }).then((response) => {
          scope.count = response.data;
        });
      }
      $interval(() => {
        scope.load();
      }, Math.floor((Math.random() * 5000) + 5000));
      scope.load();
    },
    template: `
       <div class="info-box" style="margin-bottom: 0 !important;">
           <span class="info-box-icon bg-info elevation-1"><i class="fa fa-cube"></i></span>
           <div class="info-box-content">
               <span class="info-box-text">${$localization.get('widget.count-of-cards')}</span>
               <span class="info-box-number">{{ count | number : fractionSize }}</span>
           </div>
       </div>`
  };
}]);

module.directive('cardPackagesCount', ['$timeout', '$localization', '$http', ($interval, $localization, $http) => {
  var timer;
  return {
    restrict: 'E',
    scope: {
      'options': '='
    },
    link: (scope, elem, attrs) => {
      scope.count = 0;
      scope.load = () => {
        $http({
          url: "/api/dashboard/card-packages-count/",
          method: "GET"
        }).then((response) => {
          scope.count = response.data;
        });
      }
      $interval(() => {
        scope.load();
      }, Math.floor((Math.random() * 5000) + 5000));
      scope.load();
    },
    template: `
       <div class="info-box" style="margin-bottom: 0 !important;">
           <span class="info-box-icon bg-success elevation-1"><i class="fa fa-cubes"></i></span>
           <div class="info-box-content">
               <span class="info-box-text">${$localization.get('widget.count-of-card-packages')}</span>
               <span class="info-box-number">{{ count | number : fractionSize }}</span>
           </div>
       </div>`
  };
}]);

module.directive('analogClocks', ['$timeout', '$localization', '$http', ($interval, $localization, $http) => {
  var timer;
  return {
    restrict: 'E',
    scope: {
      'label': '@',
      'timezone': '@'
    },
    link: (scope, elem, attrs) => {
      moment.locale($localization.getLanguage());
      var canvas = elem.find("canvas");
      canvas.attr('height', 600);
      canvas.attr('width', 600);
      var ctx = canvas[0].getContext("2d");
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      var radius = canvas[0].height / 2;
      ctx.translate(radius, radius);
      radius = radius * 0.90
      scope.drawClock = () => {
        scope.drawFace(ctx, radius);
        scope.drawNumbers(ctx, radius);
        scope.drawLabel(ctx, radius);
        scope.drawTime(ctx, radius);
      }
      scope.drawFace = (ctx, radius) => {
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2*Math.PI);
        ctx.fillStyle = 'white';
        ctx.fill();
        ctx.lineWidth = radius*0.015;
        ctx.strokeStyle = "#eee";
        ctx.stroke();
      }
      scope.drawLabel = (ctx, radius) => {
        ctx.font = "35px Arial";
        ctx.fillStyle = "#333"; 
        ctx.fillText(scope.label, 0, -80);
      }
      scope.drawNumbers = (ctx, radius) => {
        var ang;
        var num;
        ctx.fillStyle = "black";
        ctx.font = radius*0.15 + "px arial";
        ctx.textBaseline="middle";
        ctx.textAlign="center";
        for(num = 1; num < 13; num++){
          ang = num * Math.PI / 6;
          ctx.rotate(ang);
          ctx.translate(0, -radius*0.85);
          ctx.rotate(-ang);
          ctx.fillText(num.toString(), 0, 0);
          ctx.rotate(ang);
          ctx.translate(0, radius*0.85);
          ctx.rotate(-ang);
        }
      }
      scope.drawTime = (ctx, radius) => {
        var now = moment().tz(scope.timezone);
        var hour = now.hour();
        var minute = now.minute();
        var second = now.second();
        
        // Digital time
        ctx.font = "25px Arial";
        ctx.fillStyle = "#333";
        ctx.fillText(now.format('HH:mm:ss'), 0, 80);
       
        // Digital date
        ctx.font = "25px Arial";
        ctx.fillStyle = "#0F52BA";
        ctx.fillText(now.format('DD.MM.YYYY'), 0, 110);
       
        // Digital day
        ctx.font = "30px Arial";
        ctx.fillStyle = "#333";
        ctx.fillText(now.format('dddd'), 0, 145);
        
        // Hour
        hour=hour%12;
        hour=(hour*Math.PI/6)+
        (minute*Math.PI/(6*60))+
        (second*Math.PI/(360*60));
        ctx.strokeStyle = "rgba(119,119,119,1.0)";
        scope.drawHand(ctx, hour, radius*0.5, radius*0.05);
          
        // Minute
        minute=(minute*Math.PI/30)+(second*Math.PI/(30*60));
        ctx.strokeStyle = "rgba(119,119,119,1.0)";
        scope.drawHand(ctx, minute, radius*0.8, radius*0.05);
          
        // Second
        second=(second*Math.PI/30);
        ctx.strokeStyle = "rgba(255,193,7,1.0)";
        scope.drawHand(ctx, second, radius*0.9, radius*0.02);
      }
      scope.drawHand = (ctx, pos, length, width) => {
        ctx.beginPath();
        ctx.lineWidth = width;
        ctx.lineCap = "round";
        ctx.moveTo(0,0);
        ctx.rotate(pos);
        ctx.lineTo(0, -length);
        ctx.stroke();
        ctx.rotate(-pos);
        ctx.beginPath();
        ctx.arc(0, 0, radius*0.1, 0, 2*Math.PI);
        ctx.fillStyle = '#333';
        ctx.fill();
      }
      scope.drawClock();
      setInterval(scope.drawClock, 1000);
    },
    template: `<canvas id="canvas" class="w-100 d-block rounded"></canvas>`
  };
}]);