angular.module('cards.packages', []).controller('CardsGroupsController', ['$timeout', '$scope', '$http', '$localization', ($timeout, $scope, $http, $localization) => {
  $scope.confirmDialog = {};
  $scope.newGroup = {
    title: '',
    description: '',
    isDefault: 'NO',
    isSequenced: 'NO'
  };
  $localization.ready().then(() => {
    $scope.onPackageCreateSave = (pkg) => {
      pkg.flags = [];
      if (pkg.isDefault == 'YES') {
        pkg.flags.push('IS_DEFAULT');
      }
      if (pkg.isSequenced == 'YES') {
        pkg.flags.push('IS_SEQUENCED');
      }
      $http({
        url: "/api/cards/groups/",
        method: "POST",
        data: pkg
      }).then((response) => {
        $scope.alertNewPackage.open('SUCCESS', response.data.message, 12000);
        $scope.packagesDataTable.ajax.reload();
        $scope.newGroup = {
          title: '',
          description: '',
          isDefault: 'NO',
          isSequenced: 'NO'
        };
      }, (response) => {
        $scope.alertNewPackage.open('WARNING', response.data.message, 12000);
      });
    }
    $scope.onPackageEditSave = (pkg) => {
      pkg.flags = [];
      if (pkg.isDefault == 'YES') {
        pkg.flags.push('IS_DEFAULT');
      }
      if (pkg.isSequenced == 'YES') {
        pkg.flags.push('IS_SEQUENCED');
      }
      $http({
        url: "/api/cards/groups/",
        method: "PATCH",
        data: pkg
      }).then((response) => {
        $scope.alertEditPackage.open('SUCCESS', response.data.message, 12000);
        $scope.packagesDataTable.ajax.reload();
      }, (response) => {
        $scope.alertEditPackage.open('WARNING', response.data.message, 12000);
      });
    }
    $scope.onSearchChange = (expression) => {
      $scope.packagesDataTable.search(expression).draw();
    }
    $scope.packagesDataTable = $('.table-groups').DataTable({
      responsive: true,
      language: {
        url: `/json/datatables/${$localization.getLanguage()}.json`
      },
      ajax: {
        url: "/api/cards/groups/all/",
        type: "GET"
      },
      columns: [
        { data: "id" },
        { data: "title" },
        { data: "description" },
        { data: "cardsCount" },
        { data: "isDefault" }
      ],
      columnDefs: [{
        targets: 6,
        data: null,
        searchable: false,
        className: "text-right p-0 pt-2 pr-2",
        defaultContent: $('.table-controlls').html()
      },{
        targets: 4,
        data: null,
        searchable: true,
        bSortable: true,
        render: (data, type, row) => {
          if (data == 'YES') {
            return $localization.get('system.yes');
          } else {
            return $localization.get('system.no');
          }
        }
      },{
        targets: 5,
        data: null,
        searchable: true,
        bSortable: true,
        render: (data, type, row) => {
          if (row.flags.includes('IS_SEQUENCED')) {
            return $localization.get('system.yes');
          } else {
            return $localization.get('system.no');
          }
        }
      }],
      sDom: 't<"card-footer border-top card-footer-pagination"<"row"<"col-6"i><"col-6"p>>>'
    });
    $('.table-groups tbody').on('click', 'button.btn-edit-group', function(event) {
      $scope.editGroup = $scope.packagesDataTable.row($(this).parents('tr')).data();
      $scope.editGroup.isSequenced = $scope.editGroup.flags.includes('IS_SEQUENCED') ? 'YES' : 'NO';
      $('#edit-package-modal').modal('show');
      $scope.$apply();
    });
    $('.table-groups tbody').on('click', 'button.btn-delete-group', function(event) {
      var data = $scope.packagesDataTable.row($(this).parents('tr')).data();
      var messages = {
        title: $localization.get('cards.admin.group.delete.dialog.title'),
        message: $localization.get('cards.admin.group.delete.dialog.message'),
        cancelText: $localization.get('system.no'),
        confirmText: $localization.get('system.yes')
      };
      $scope.confirmDialog.open(messages).then((response) => {
        $http({
          url: "/api/cards/groups/",
          method: "DELETE",
          params: {
            groupId: data.id,
          }
        }).then((response) => {
          $scope.packagesDataTable.ajax.reload();
          $scope.alertPackages.open('SUCCESS', response.data.message, 12000);
        }, (response) => {
          $scope.alertPackages.open('WARNING', response.data.message, 12000);
        });
      });
      $scope.$apply();
    });
  });
}]);