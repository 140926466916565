angular.module('vocabulary.words', ['twbs']).controller('VocabularyWordController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.vocabularyId = $('vocabulary').data('vocabulary-id');
  $scope.hasTokens = $('vocabulary').data('has-tokens');
  $scope.hasFrequency = $('vocabulary').data('has-frequency');
  $scope.hasPAV = $('vocabulary').data('has-pav');
  $scope.hasIPA = $('vocabulary').data('has-ipa');
  $scope.hasOtherPhonetic = $('vocabulary').data('has-other-phonetic');
  $scope.searchingPromise = null;
  
  $scope.newSourceWordAjaxParams = {
    language: 'cs'
  }
  
  $scope.newTargetWordAjaxParams = {
    language: 'en',
    sourceWordIds: [],
    targetWordIds: []
  }
  
  $scope.newSourceWordOptions = [];
  $scope.newTargetWordOptions = [];
  $scope.newPAVWordOptions = [];
  
  $scope.newSourceWords = [];
  $scope.newTargetWords = [];
  $scope.newIPAWords = [];
  $scope.newPAVWords = [];
  $scope.newOtherWords = [];
  
  $scope.onNewSourceWordChanged = () => {
    $scope.newTargetWordAjaxParams.sourceWordIds = $scope.newSourceWords.map(word => {
      return word.sourceWordId
    });
    $scope.newTargetWordAjaxParams.sourceWordIds = $scope.newTargetWordAjaxParams.sourceWordIds.filter(value => {
      return Number.isInteger(value);
    });
    $scope.newTargetWordAjaxParams.sourceWordIds = $scope.newTargetWordAjaxParams.sourceWordIds.join(',');
  }
  
  $scope.onNewTargetWordChanged = () => {
    console.log("changed");
    $scope.newTargetWordAjaxParams.targetWordIds = $scope.newTargetWords.map(word => {
      return word.targetWordId
    });
    $scope.newTargetWordAjaxParams.targetWordIds = $scope.newTargetWordAjaxParams.targetWordIds.filter(value => {
      return Number.isInteger(value);
    });
    $scope.newTargetWordAjaxParams.targetWordIds = $scope.newTargetWordAjaxParams.targetWordIds.join(',');
    console.log($scope.newTargetWordAjaxParams.targetWordIds);
  }
  
  $scope.onSearchChange = (expression) => {
    if ($scope.searchingPromise != null) {
      $timeout.cancel($scope.searchingPromise);
    }
    $scope.searchingPromise = $timeout(() => {
      $scope.wordsDataTable.search(expression).draw();
    }, 1000);
  };
  $localization.ready().then(() => {
    var columns = [];
    var columnDefs = [];
    var colIdx = 0;
    
    columns.push({ data: "vocabularyWordId" });
    columnDefs.push({ targets: colIdx++, className: 'font-weight-bold' });
    
    columns.push({ data: "sourceWord" });
    columnDefs.push({ targets: colIdx++ });
    
    columns.push({ data: "targetWord" });
    columnDefs.push({ targets: colIdx++ });
    
    if ($scope.hasIPA) {
      columns.push({ data: "phoneticIPA" });
      columnDefs.push({ targets: colIdx++ });
    }
    
    if ($scope.hasPAV) {
      columns.push({ data: "phoneticPAV" });
      columnDefs.push({ targets: colIdx++ });
    }
    
    if ($scope.hasOtherPhonetic) {
      columns.push({ data: "phoneticOther" });
      columnDefs.push({ targets: colIdx++ });
    }
    
    if ($scope.hasTokens) {
      columns.push({ data: "tokens" });
      columnDefs.push({ 
        targets: colIdx++,
        render: (data, type, row) => {
          return row.tokens == null ? `<span class="text-muted">${$localization.get('system.none')}</span>` : row.tokens;
        },
        className: 'font-italic'
      });
    }
    
    if ($scope.hasFrequency) {
      columns.push({ data: "frequency" });
      columnDefs.push({ 
        targets: colIdx++,
        render: (data, type, row) => {
          return row.frequency == null ? `<span class="text-muted">${$localization.get('system.none')}</span>` : row.frequency;
        },
        className: 'font-italic'
      });
    }
    
    columnDefs.push({
      targets: colIdx++,
      data: null,
      searchable: false,
      className: "text-right p-0 pt-2 pr-2",
      defaultContent: `
        <div class="text-nowrap">
            <button class="btn btn-sm btn-danger btn-delete" data-toggle="tooltip" title="${$localization.get('system.delete')}" ${$scope.allowDelete}>
                <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
            </button>
            <button class="btn btn-sm btn-success btn-edit" data-toggle="tooltip" title="${$localization.get('system.edit')}" ${$scope.allowEdit}>
                <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>
            </button>
        </div>`
    });

    $scope.wordsDataTable = $('.table-words').DataTable({
      searchDelay: 100,
      aLengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
      responsive: true,
      serverSide: true,
      language: {
        url: `/json/datatables/${$localization.getLanguage()}.json`
      },
      ajax: {
        url: "/api/words/",
        type: "POST",
        data: function(data) {
          var tmp = JSON.stringify(data);
          Object.keys(data).forEach((key) => { 
            delete data[key];
          });
          data.dtRequest = tmp;
          data.vocabularyId = $scope.vocabularyId;
        }
      },
      columns: columns,
      columnDefs: columnDefs,
      sDom: 't<"card-footer border-top card-footer-pagination"<"row"<"col-3"l><"col-3"i><"col-6"p>>>'
    });
    
    $scope.wordsDataTable.on('draw', function() {
      $('[data-toggle="tooltip"]').tooltip(); 
    });
    
    $('.table-words tbody').on('click', 'button.btn-delete', function(event) {
      var row = $scope.wordsDataTable.row($(this).parents('tr')).data();
      var messages = {
        title: $localization.get('vocabulary.words.delete.confirmation-title'),
        message: $localization.get('vocabulary.words.delete.confirmation-message'),
        cancelText: $localization.get('system.no'),
        confirmText: $localization.get('system.yes')
      };
      $scope.confirmDialog.open(messages).then((response) => {
        $http({
          url: "/api/words/",
          method: "DELETE",
          params: {
            vocabularyWordId: row.vocabularyWordId
          }
        }).then((response) => {
          $scope.wordsAlert.open('SUCCESS', response.data.message, 8000);
          $scope.wordsDataTable.ajax.reload();
        }, (response) => {
          $scope.wordsAlert.open('DANGER', response.data.message, 12000);
        });
      });
      $scope.$apply();
    });
    
    $('.table-words tbody').on('click', 'button.btn-edit', function(event) {
      var row = $scope.wordsDataTable.row($(this).parents('tr')).data();
      alert('Not implemented yet.');
    });
    
  });
}]);