/**
 * 
 * Simple bootstrap alert plugin
 * @author Jan Lošťák
 * @version 1.0
 * 
 */
$.fn.bootstrapAlert = function() {
	var a = this;
	var timeout;
	
    /**
     * Reset alert
     */
    var reset = function() {
    	a.hide();
    	
        a.removeClass('alert-success');
        a.removeClass('alert-info');
        a.removeClass('alert-warning');
        a.removeClass('alert-danger');
    }
    
    /**
     * Display alert for given time
     * @param class of alert
     * @param message to show
     * @param hide time
     */
    var display = function(aClass, aMessage, aTime) {
    	reset();
    	
    	a.addClass(aClass);
    	var messageElem = a.find('.alert-message');
    	
    	if (messageElem.length)
    		messageElem.text(aMessage);
    	else
    		a.html(aMessage);
    	
    	a.show();
    	
    	clearTimeout(timeout);
    	timeout = setTimeout(function() {
        	a.fadeOut(2000, function(event) {
        		reset();
        	});
    	}, aTime);
    }
    
    a.find('.close').on('click', function(event) {
    	clearTimeout(timeout);
    	a.fadeOut(2000, function(event) {
    		reset();
    	});
    });

    reset();
    
    // Return public API
    return {
    	display: display
    };
}