angular.module('cards.edit', []).controller('CardsEditController', ['$timeout', '$scope', '$http', '$localization', ($timeout, $scope, $http, $localization) => {
  var CardContentType = {
    TEXT: 'TEXT',
    HTML: 'HTML',
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO'
  };
  var allowedAudioTypes = ['audio/mp3', 'audio/ogg', 'audio/wav', 'audio/mpeg'];
  var allowedVideoTypes = ['video/mp4', 'video/ogg', 'video/mpeg'];
  var allowedImageTypes = ['image/jpg', 'image/jpeg', 'image/gif', 'image/gifv', 'image/png', 'image/svg'];
  $scope.confirmDialog = {};
  $scope.newCardProgress = false;
  $scope.resetNewCardForm = () => {
    $scope.newCard = {
      title: '',
      description: '',
      includedInGroups: [],
      content: {
        FRONT: {
          type: 'TEXT',
          side: 'FRONT',
          title: '',
          description: '',
          content: '',
          flipTime: 3,
          links: []
        },
        BACK: {
          type: 'TEXT',
          side: 'BACK',
          title: '',
          description: '',
          content: '',
          flipTime: 3,
          links: []
        }
      }
    };
  }
  $scope.resetNewCardForm();
  $scope.editCard = {
    includedInGroups: []
  };
  $scope.onCardEditSave = (card) => {
    $scope.editCardProgress = true;
    $scope.editCardProgressPercent = 0;
    var formData = new FormData();
    if (card.content.FRONT.fileToUpload) {
      formData.append('front', card.content.FRONT.fileToUpload);
    }
    if (card.content.BACK.fileToUpload) {
      formData.append('back', card.content.BACK.fileToUpload);
    }
    formData.append('card', JSON.stringify(card));
    $.ajax({
      url: '/api/cards/update/',
      type: 'POST',
      xhr: function() {
        var myXhr = $.ajaxSettings.xhr();
        if (myXhr.upload) {
          myXhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              $scope.editCardProgressPercent = Math.ceil((event.loaded / event.total) * 100);
              $scope.$apply();
            }
          }, false);
        }
        return myXhr;
      },
      success: function(result) {
        $scope.alertEditCard.open('SUCCESS', result.message, 12000);
        $scope.cardsDataTable.ajax.reload();
        $scope.editCardProgress = false;
        $scope.$apply();
      },
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).fail(function(xhr) {
      if (xhr.responseJSON) {
        $scope.alertEditCard.open('WARNING', xhr.responseJSON.message, 12000);
        $scope.editCardProgress = false;
        $scope.$apply();
      }
    });
  }
  $scope.onCardCreateSave = (card) => {
    $scope.newCardProgress = true;
    $scope.newCardProgressPercent = 0;
    var formData = new FormData();
    if (card.content.FRONT.fileToUpload) {
      formData.append('front', card.content.FRONT.fileToUpload);
    }
    if (card.content.BACK.fileToUpload) {
      formData.append('back', card.content.BACK.fileToUpload);
    }
    formData.append('card', JSON.stringify(card));
    $.ajax({
      url: '/api/cards/create/',
      type: 'POST',
      xhr: () => {
        var myXhr = $.ajaxSettings.xhr();
        if (myXhr.upload) {
          myXhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              $scope.newCardProgressPercent = Math.ceil((event.loaded / event.total) * 100);
              $scope.$apply();
            }
          }, false);
        }
        return myXhr;
      },
      success: (result) => {
        $scope.alertNewCard.open('SUCCESS', result.message, 12000);
        $scope.resetNewCardForm();
        $scope.cardsDataTable.ajax.reload();
        $scope.newCardProgress = false;
        $scope.$apply();
      },
      error: (xhr, statusText, error) => {
        if (!xhr.responseJSON) {
          $scope.alertNewCard.open('DANGER', $localization.get('cards.admin.card.failed-connection-timeout'), 12000);
          $scope.newCardProgress = false;
          $scope.$apply();
        }
      },
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).fail((xhr) => {
      if (xhr.responseJSON) {
        $scope.alertNewCard.open('WARNING', xhr.responseJSON.message, 12000);
        $scope.newCardProgress = false;
        $scope.$apply();
      }
    });
  }
  $scope.deleteLink = (content, link) => {
    var messages = {
      title: $localization.get('cards.admin.card.links.delete.link.title'),
      message: $localization.get('cards.admin.card.links.delete.link.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      content.links = content.links.filter((obj) => {
        return obj.$$hashKey != link.$$hashKey;
      });
    });
  }
  $scope.addLink = (content) => {
    console.log(content);
    content.links.push({
      id: null,
      cardContentId: content.id,
      description: "",
      link: ""
    });
  }
  $localization.ready().then(() => {
    $http({
      url: "/api/cards/groups/",
      method: "GET"
    }).then((response) => {
      $scope.cardPackages = response.data;
    });
    $scope.onSearchChange = (expression) => {
      $scope.cardsDataTable.search(expression).draw();
    }
    $scope.cardsDataTable = $('.table-cards').DataTable({
      responsive: true,
      language: {
        url: `/json/datatables/${$localization.getLanguage()}.json`
      },
      ajax: {
        url: "/api/cards/all/",
        type: "GET"
      },
      columns: [
        { data: "id" },
        { data: "title" },
        { data: "description" }
      ],
      columnDefs: [{
        targets: 3,
        data: null,
        bSortable: true,
        searchable: true,
        render: (data, type, row) => {
          if (data.includedInGroups.length == 0) {
            return $localization.get('system.none');
          }
          var groups = [];
          data.includedInGroups.forEach((group) => {
            groups.push(group.title);
          });  
          return groups.join(", ");
        }
      },{
        targets: 4,
        data: null,
        searchable: false,
        className: "text-right p-0 pt-2 pr-2",
        defaultContent: $('.table-controlls').html()
      }],
      sDom: 't<"card-footer border-top card-footer-pagination"<"row"<"col-6"i><"col-6"p>>>'
    });
    $('.table-cards tbody').on('click', 'button.btn-edit-card', function(event) {
      $scope.editCard = $scope.cardsDataTable.row($(this).parents('tr')).data();
      $('#edit-card-modal').modal('show');
      $scope.$apply();
    });
    $('.table-cards tbody').on('click', 'button.btn-delete-card', function(event) {
      var data = $scope.cardsDataTable.row($(this).parents('tr')).data();
      var messages = {
        title: $localization.get('cards.admin.card.delete.dialog.title'),
        message: $localization.get('cards.admin.card.delete.dialog.message'),
        cancelText: $localization.get('system.no'),
        confirmText: $localization.get('system.yes')
      };
      $scope.confirmDialog.open(messages).then((response) => {
        $http({
          url: "/api/cards/",
          method: "DELETE",
          params: {
            cardId: data.id,
          }
        }).then((response) => {
          $scope.cardsDataTable.ajax.reload();
          $scope.alertCards.open('SUCCESS', response.data.message, 12000);
        }, (response) => {
          $scope.alertCards.open('WARNING', response.data.message, 12000);
        });
      });
      $scope.$apply();
    });
    $scope.validateFileDropEvent = (event, cardContent) => {
      if (event.originalEvent.dataTransfer) {
        if (event.originalEvent.dataTransfer.files.length) {
          event.preventDefault();
          event.stopPropagation();
          if (event.originalEvent.dataTransfer.files.length > 1) {
            $scope.alertNewCard.open('WARNING', $localization.get('cards.admin.select.just.one.file'), 12000);
            $scope.$apply();
            return;
          }
          var file = event.originalEvent.dataTransfer.files[0];
          switch (cardContent.type) {
            case CardContentType.AUDIO: {
              if (!allowedAudioTypes.includes(file.type)) {
                $scope.alertNewCard.open('WARNING', $localization.get('cards.admin.invalid.audio.type'), 12000);
                $scope.$apply();
                return;
              }
              break;
            }
            case CardContentType.VIDEO: {
              if (!allowedVideoTypes.includes(file.type)) {
                $scope.alertNewCard.open('WARNING', $localization.get('cards.admin.invalid.video.type'), 12000);
                $scope.$apply();
                return;
              }
              break;
            }
            case CardContentType.IMAGE: {
              if (!allowedImageTypes.includes(file.type)) {
                $scope.alertNewCard.open('WARNING', $localization.get('cards.admin.invalid.image.type'), 12000);
                $scope.$apply();
                return;
              }
              break;
            }
            default: return;
          }
          if ($('file-config').data('max-file-size') < file.size) {
            $scope.alertNewCard.open('WARNING', $localization.get('cards.admin.file.too.big'), 12000);
            $scope.$apply();
            return;
          }
          cardContent.fileToUpload = file;
          cardContent.file = {
            name: file.name,
            size: file.size,
            mimeType: file.type
          }
          $scope.$apply();
        }
      }
    }
    $('.file-drop-zone').on('dragover dragenter', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    $('.new-card-front-drop-zone').on('drop', (event) => {
      $scope.validateFileDropEvent(event, $scope.newCard.content.FRONT);
    });
    $('.new-card-back-drop-zone').on('drop', (event) => {
      $scope.validateFileDropEvent(event, $scope.newCard.content.BACK);
    });
    $('.edit-card-front-drop-zone').on('drop', (event) => {
      $scope.validateFileDropEvent(event, $scope.editCard.content.FRONT);
    });
    $('.edit-card-back-drop-zone').on('drop', (event) => {
      $scope.validateFileDropEvent(event, $scope.editCard.content.BACK);
    });
  });
}]);