angular.module('user', ['ui.select2', 'ui.sortable', 'ui.mask', 'ui.datetimepicker', 'twbs']).controller('UserCardsController', ['$timeout', '$scope', '$http', '$localization', ($timeout, $scope, $http, $localization) => {
  $scope.usersDataTable = null;
  $scope.openedCards = [];
  $scope.loginAsTrackingActivity = [];
  $scope.loginAsTrackingLinkIds = [];
  $scope.loginAsAlertTypes = [];
  $scope.loginAsAlertType = [];
  $scope.confirmDialog = {};
  $scope.loginAsEditId = 0;
  $scope.loginAsEditUserId = 0;
  $scope.loginAsActivitiesMaxPerPage = 12;
  $scope.loginAsMenuTarget = 1;
  $scope.loginAsType = 'VIEW_ONLY';
  $scope.loginAsLinkType = 'RANDOM';
  $scope.loginAsExpirationType = 'NONE';
  $scope.loginAsDialogProgress = false;
  $scope.loginAsEdit = false;
  $scope.loginAsCreate = false;
  $scope.loginAsAlreadyExists = false;
  $scope.locale = $localization;
  $scope.dateTimeFormat =  { 
    alias: 'datetime', 
    inputFormat: "yyyy-mm-dd HH:MM:ss"
  };
  $scope.newCard = {
    name: '',
    email: ''
  };
  $http({
    url: "/api/enum/degrees/prefix/",
    method: "GET"
  }).then((response) => {
    $scope.prefixDegrees = response.data;
  });
  $http({
    url: "/api/enum/degrees/suffix/",
    method: "GET"
  }).then((response) => {
    $scope.suffixDegrees = response.data;
  });
  $http({
    url: "/api/roles/",
    method: "GET"
  }).then((response) => {
    $scope.rolesOptions = response.data;
  });
  $http({
    url: "/api/user/permitted/",
    method: "GET"
  }).then((response) => {
    $scope.permittedUsers = response.data;
  });
  $http({
    url: "/api/cards/groups/nondefault/",
    method: "GET"
  }).then((response) => {
    $scope.cardPackages = response.data;
  });
  $http({
    url: "/api/locale/country/codes/",
    method: "GET"
  }).then((response) => {
    var options = [];
    angular.forEach(response.data, (name, code) => {
      options.push({ id: code, text: name });
    });
    $scope.countryCodes = options;
  });
  $http({
    url: "/api/locale/language/codes/",
    method: "GET"
  }).then((response) => {
    var options = [];
    angular.forEach(response.data, (name, code) => {
      options.push({ id: code, text: name });
    });
    $scope.languageCodes = options;
  });
  $http({
    url: "/api/qmail/batches/",
    method: "GET"
  }).then((response) => {
    $scope.mailBatches = response.data;
  });
  $scope.resetLoginAsAlertForm = () => {
    $scope.loginAsAlertType = [];
    $scope.loginAsAlertTypes = [
      {id: 'ALERT_NOTIFICATION', text: $localization.get('card.of.user.login-as-alert.notification')},
      {id: 'ALERT_EMAIL', text: $localization.get('card.of.user.login-as-alert.email')},
      {id: 'ALERT_PHONE_SMS', text: $localization.get('card.of.user.login-as-alert.sms')},
      {id: 'ALERT_PHONE_CALL', text: $localization.get('card.of.user.login-as-alert.call')},
    ];
    $scope.loginAsEditId = 0;
    $scope.loginAsMenuTarget = 1;
    $scope.loginAsExpirationMaxClicks = 1;
    $scope.loginAsExpirationType = 'NONE';
    $scope.loginAsType = 'VIEW_ONLY';
    $scope.loginAsLinkType = 'RANDOM';
    $scope.loginAsIdentifier = '';
    $scope.loginAsExpirationType = 'NONE';
    $scope.loginAsLinkName = '';
  }
  $scope.createLoginAsObject = () => {
    var flags = [];
    flags.push('LOGIN_AS_USER');
    flags.push('MENU_REDIRECT');
    flags.push('TRACKING');
    
    if ($scope.loginAsType == 'VIEW_ONLY') {
      flags.push('LOGIN_AS_USER_VIEW_ONLY');
    }
    
    if ($scope.loginAsLinkType == 'CUSTOM') {
      flags.push('CUSTOM');
    }
    
    if ($scope.loginAsExpirationType == 'DATE') {
      flags.push('HAS_EXPIRATION_DATE');
    }
    else if ($scope.loginAsExpirationType == 'CLICK_LIMIT') {
      flags.push('LIMITED_USE');
    }
    else if ($scope.loginAsExpirationType == 'ONE_CLICK') {
      flags.push('ONE_TIME_USE');
    } 
    else if ($scope.loginAsExpirationType == 'NONE') {
      flags.push('PERMANENT_USE');
    }
    
    $scope.loginAsAlertType.forEach((e) => {
      flags.push(e.id);
    });

    return {
      id: $scope.loginAsEditId,
      loginAsUserId: $scope.loginAsEditUserId,
      targetMenuItemId: $scope.loginAsMenuTarget,
      flags: flags,
      key: $scope.loginAsIdentifier,
      maxClicks: $scope.loginAsExpirationMaxClicks,
      expirationDate: $scope.loginAsExpirationDate,
      comment: $scope.loginAsLinkName
    };
  }
  $scope.onTrackingLinkSave = () => {
    $scope.loginAsDialogProgress = true;
    $timeout(() => {
      $http({
        url: "/api/user/login-as-links/",
        method: "PUT",
        data: $scope.createLoginAsObject()
      }).then((response) => {
        $scope.alertLoginAs.open('SUCCESS', response.data.message, 12000);
        $scope.loginAsEdit = false;
        $scope.loginAsCreate = false;
        $scope.loginAsDialogProgress = false;
        $scope.loadUserLoginAsLinksByUser($scope.loginAsEditUserId);
        $scope.resetLoginAsAlertForm();
      }, (response) => {
        $scope.alertLoginAs.open('DANGER', response.data.message, 12000);
        $scope.loginAsDialogProgress = false;
      });
    }, 500);
  }
  $scope.onTrackingLinkEditCancel = () => {
    $scope.loginAsDialogProgress = true;
    $timeout(() => {
      $scope.loginAsEdit = false;
      $scope.loginAsCreate = false;
      $scope.resetLoginAsAlertForm();
      $scope.loginAsDialogProgress = false;
    }, 500);
  }
  $scope.onEditTrackingLink = (trackingLink) => {
  	$scope.loginAsDialogProgress = true;
  	$timeout(() => {
  		$scope.loginAsEdit = true;
  		$scope.loginAsCreate = false;
  		$scope.resetLoginAsAlertForm();

  		$scope.loginAsEditId = trackingLink.id;
  		$scope.loginAsType = $scope.hasTrackingLinkFlag(trackingLink, 'LOGIN_AS_USER_VIEW_ONLY') ? 'VIEW_ONLY' : 'FULL';
  		$scope.loginAsLinkType = $scope.hasTrackingLinkFlag(trackingLink, 'CUSTOM') ? 'CUSTOM' : 'RANDOM';
  		$scope.loginAsIdentifier = trackingLink.key;
  		$scope.loginAsMenuTarget = trackingLink.targetMenuItem.id;
  		$scope.loginAsExpirationDate = trackingLink.expirationDate;
  		$scope.loginAsExpirationMaxClicks = Math.max(trackingLink.maxClicks, 1);
  		$scope.loginAsLinkName = trackingLink.comment;
  
  		if ($scope.hasTrackingLinkFlag(trackingLink, 'HAS_EXPIRATION_DATE')) {
  		  $scope.loginAsExpirationType = 'DATE';
  		} else if ($scope.hasTrackingLinkFlag(trackingLink, 'LIMITED_USE')) {
  		  $scope.loginAsExpirationType = 'CLICK_LIMIT';
  		} else if ($scope.hasTrackingLinkFlag(trackingLink, 'ONE_TIME_USE')) {
  		  $scope.loginAsExpirationType = 'ONE_CLICK';
  		} else if ($scope.hasTrackingLinkFlag(trackingLink, 'PERMANENT_USE')) {
  		  $scope.loginAsExpirationType = 'NONE';
  		}
  		
  		['ALERT_NOTIFICATION', 'ALERT_EMAIL', 'ALERT_PHONE_SMS', 'ALERT_PHONE_CALL'].forEach((e) => {
        if ($scope.hasTrackingLinkFlag(trackingLink, e)) {
          $scope.loginAsAlertType.push({
            id: e, text: ''
          });
        }
  		});
  		
  		$scope.loginAsDialogProgress = false;
  	}, 500);
  }
  $scope.onDeleteTrackingLink = (trackingLink) => {
    var messages = {
      title: $localization.get('card.of.user.login-as-modal.delete-confirm-header'),
      message: $localization.get('card.of.user.login-as-modal.delete-confirm-message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      $scope.loginAsDialogProgress = true;
      $http({
  	    url: "/api/user/login-as-links/",
  	    method: "DELETE",
  	    params: {
  	      linkId: trackingLink.id
  	    }
      }).then((response) => {
        $scope.alertLoginAs.open('SUCCESS', response.data.message, 12000);
        $scope.loginAsLinks = $scope.loginAsLinks.filter((element) => {
          return element.$$hashKey != trackingLink.$$hashKey;
        });
        $scope.loadUserLoginAsLinksByUser($scope.loginAsEditUserId);
        $scope.loginAsDialogProgress = false;
  	  });
    });
  }
  $scope.onCreateTrackingLink = () => {
    $scope.loginAsEdit = false;
    $scope.loginAsCreate = true;
    $scope.resetLoginAsAlertForm();
    $scope.loginAsAlertType.push({
      id: 'ALERT_NOTIFICATION', text: ''
    });
  }
  $scope.hasTrackingLinkFlag = (trackingLink, flag) => {
  	for (var i = 0; i < trackingLink.flags.length; i++) {
      if (trackingLink.flags[i] == flag) {
        return true;
      }
  	}
  	return false;
  }
  $scope.loadUserLoginAsLinksByUser = (userId) => {
    $scope.loginAsDialogProgress = true;
    $http({
      url: "/api/user/login-as-links/",
      method: "GET",
      params: {
        userId: userId
      }
    }).then((response) => {
      $scope.loginAsTrackingLinkIds = [];
      $scope.loginAsLinks = response.data;
      $scope.loginAsLinks.forEach((link) => {
        $scope.loginAsTrackingLinkIds.push(link.id);
      });
      $scope.loadLoginAsTrackingLinksActivity($scope.loginAsTrackingLinkIds, 0, $scope.loginAsActivitiesMaxPerPage);
    });
  }
  $scope.loadUserLoginAsLinks = (openedCard) => {
  	$('#login-as-dialog').modal('show');
  	$scope.loginAsDialogProgress = true;
  	$scope.loginAsEditUserId = openedCard.openedUser.id;
  	$scope.loadUserLoginAsLinksByUser(openedCard.openedUser.id);
  	
  	$scope.loginAsName = openedCard.openedUser.name;
  	$scope.loginAsEmail = openedCard.openedUser.email;
  }
  $scope.onLinkCopy = (trackingLink) => {
    $scope.copyToClipboard(`${window.location.protocol}//${window.location.host}/${trackingLink.key}`);
    $scope.alertLoginAs.open('SUCCESS', $localization.get('card.of.user.login-as.copied'), 6000);
  }
  $scope.loadLoginAsTrackingLinksActivity = (linkIds, startPage, resultsPerPage) => {
    $scope.loginAsDialogProgress = true;
    $http({
      url: "/api/tracking/activity/",
      method: "GET",
      params: {
        linkIds: linkIds.join(','),
        startPage: startPage,
        resultsPerPage: resultsPerPage
      }
    }).then((response) => {
      $scope.loginAsTrackingActivity = response.data;
      $scope.loginAsDialogProgress = false;
    });
  }
  $scope.loadOpenedCards = () => {
    $http({
      url: "/api/user/opened/cards/",
      method: "GET"
    }).then((response) => {
      $scope.openedCards = response.data;
    });
  }
  $scope.saveOpenedCardsOrder = () => {
    var orderIds = [];
    $scope.openedCards.forEach((openedCard, idx) => {
      if (openedCard.openUserId != null)
        orderIds.push(openedCard.id);
    });
    $http({
      url: "/api/user/opened/cards/order/save/",
      method: "POST",
      params: {
        'orderIds': orderIds.join(",")
      }
    });
  }
  $scope.onUserCardOpen = (user) => {
    var foundOpenedCard = null;
    $scope.openedCards.forEach((openedCard, idx) => {
      if (openedCard.openedUser != null)
        if (openedCard.openedUser.id == user.id)
          foundOpenedCard = openedCard;

      openedCard.sort = idx + 1;
    });
    if (foundOpenedCard == null) {
      var openCard = {
        id: null,
        userId: null,
        openUserId: user.id,
        isOpened: true,
        tabIndex: 0,
        sort: 0,
        openedUser: user
      }
      $http({
        url: "/api/user/opened/card/open/",
        method: "POST",
        data: openCard
      }).then((response) => {
        openCard.id = response.data.id;
        openCard.userId = response.data.userId;
        $scope.openedCards.unshift(openCard);
        $scope.openedCards.sort((a, b) => {
          return a.sort - b.sort;
        });
        $scope.saveOpenedCardsOrder();
        $scope.focusOnOpenedCard(response.data.userId, openCard);
      }, (response) => {
        $scope.alertCard.open('WARNING', response.data.message, 12000);
      });
    } else {
      $scope.focusOnOpenedCard(user.id, foundOpenedCard);
    }
  }
  $scope.focusOnOpenedCard = (userId, openedCard) => {
    var element = document.getElementById(`user-card-${userId}`);
    $(element).removeClass('collapsed-card');
    $(element).find('.card-body').css('display', 'block');
    $(element).find('.card-footer').css('display', 'block');
    element.scrollIntoView();
    if (openedCard != null) {
      if (!openedCard.isOpened) {
        $http({
          url: "/api/user/opened/card/toggle/",
          method: "POST",
          params: {
            userCardOpenedId: openedCard.id
          }
        });
        openedCard.isOpened = true;
      }
    }
  }
  $scope.onSearchChange = (expression) => {
    $scope.usersDataTable.search(expression).draw();
    if ($('.card-users').hasClass('collapsed-card')) {
      $('.card-users').removeClass('collapsed-card');
      $('.card-users .card-body').css('display', 'block');
      $('.card-users .card-footer').css('display', 'block');
      $scope.onCardSearchPanelToggle();
    }
  }
  $scope.onCardCreateSave = (newCard) => {
    $http({
      url: "/api/user/create/",
      method: "POST",
      params: {
        name: newCard.name,
        email: newCard.email
      }
    }).then((response) => {
      $scope.alertNewCard.open('SUCCESS', $localization.get('card.of.user.new.card.created'), 12000);
      $scope.usersDataTable.ajax.reload();
      $scope.newCard = {
        name: '',
        email: ''
      };
      $scope.onUserCardOpen(response.data);
    }, (response) => {
      $scope.alertNewCard.open('DANGER', response.data.message, 12000);
    });
  }
  $scope.onTabClick = (openedCard, tabIndex) => {
    $http({
      url: "/api/user/opened/card/save/tabindex/",
      method: "POST",
      params: {
        openedCardId: openedCard.id,
        tabIndex: tabIndex
      }
    });
  }
  $scope.onCardSearchPanelToggle = () => {
    $http({
      url: "/api/user/options/toggle/",
      method: "POST",
      params: {
        option: "card-of-user-search-collapsed"
      }
    });
  }
  $scope.onOpenedCardToggle = (openedCard) => {
    if (openedCard.openUserId != null) {
      $http({
        url: "/api/user/opened/card/toggle/",
        method: "POST",
        params: {
          userCardOpenedId: openedCard.id
        }
      });
    }
  }
  $scope.onOpenedCardClose = (openedCard) => {
    var messages = {
      title: $localization.get('card.of.user.close.title'),
      message: $localization.get('card.of.user.close.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      if (openedCard.openUserId != null) {
        $scope.openedCards = $scope.openedCards.filter((element) => {
          return element.id != openedCard.id;
        });
        $http({
          url: "/api/user/opened/card/close/",
          method: "POST",
          params: {
            userCardOpenedId: openedCard.id
          }
        });
      } else {
        $scope.openedCards = $scope.openedCards.filter((element) => {
          return element.$$hashKey != openedCard.$$hashKey;
        });
      }
    });
  }
  $scope.deleteCard = (openedCard) => {
    var messages = {
      title: $localization.get('card.of.user.delete.title'),
      message: $localization.get('card.of.user.delete.message', [openedCard.openedUser.name]),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      openedCard.loading = true;
      $timeout(() => {
        $http({
          url: "/api/user/",
          method: "DELETE",
          data: openedCard.openedUser,
          params: {
            userId: openedCard.openedUser.id,
          }
        }).then((response) => {
          $scope.alertCard.open('SUCCESS', response.data.message, 12000);
          $scope.openedCards = $scope.openedCards.filter((element) => {
            return element.$$hashKey != openedCard.$$hashKey;
          });
          $scope.usersDataTable.ajax.reload();
        }, (response) => {
          openedCard.loading = false;
          openedCard.alert.open('WARNING', response.data.message, 12000);
        });
      }, 1000);
    });
  }
  $scope.copyToClipboard = (content) => {
    var copyInput = document.getElementById("loginAsLinkInput");
    copyInput.value = content;
    copyInput.select();
    document.execCommand("copy");
  }
  $scope.saveCard = (openedCard) => {
    openedCard.loading = true;
    $timeout(() => {
      $http({
        url: "/api/user/",
        method: "POST",
        data: openedCard.openedUser
      }).then((response) => {
        openedCard.loading = false;
        openedCard.alert.open('SUCCESS', $localization.get('card.of.user.card.saved'), 12000);
        openedCard.openedUser = response.data;
      }, (response) => {
        openedCard.loading = false;
        openedCard.alert.open('WARNING', response.data.message, 12000);
      });
    }, 1000);
  }
  $scope.sortableCards = {
    stop: (e, ui) => {
      $scope.saveOpenedCardsOrder();
    },
    handle: '.card-header-handler',
    placeholder: 'sort-highlight',
    forcePlaceholderSize: true
  };
  $scope.contactAddEmail = (contact) => {
    contact.emails.push({ id: null, userContactId: null, email: '' });
  }
  $scope.contactAddPhone = (contact) => {
    contact.phones.push({ id: null, userContactId: null, phone: '' });
  }
  $scope.contactDeleteEmail = (contact, email) => {
    var messages = {
      title: $localization.get('card.of.user.contact.delete.email.title'),
      message: $localization.get('card.of.user.contact.delete.email.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      contact.emails = contact.emails.filter((obj) => {
        return obj.$$hashKey != email.$$hashKey;
      });
    });
  }
  $scope.contactDeletePhone = (contact, phone) => {
    var messages = {
      title: $localization.get('card.of.user.contact.delete.phone.title'),
      message: $localization.get('card.of.user.contact.delete.phone.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      contact.phones = contact.phones.filter((obj) => {
        return obj.$$hashKey != phone.$$hashKey;
      });
    });
  }
  $scope.createContact = (userCard) => {
    userCard.contacts.push({
      userId: userCard.id,
      type: 'INDIVIDUAL',
      company: '',
      webPages: '',
      position: '',
      firstName: '',
      lastName: '',
      gender: 'NOT_SPECIFIED',
      companyId: '',
      taxId: '',
      street: '',
      city: '',
      region: '',
      zip: '',
      countryCode: null,
      remarks: '',
      emails: [],
      phones: [],
      prefixDegrees: [],
      suffixDegrees: []
    });
  }
  $scope.checkNewPassword = (openedCard) => {
    if (openedCard.newPassword1 && openedCard.newPassword2) {
      openedCard.isNewPasswordValid = openedCard.newPassword1 == openedCard.newPassword2;
      if (openedCard.isNewPasswordValid) {
        openedCard.openedUser.password = openedCard.newPassword1;
      }
    } else {
      openedCard.isNewPasswordValid = true;
      openedCard.openedUser.password = null;
    }
  }
  $scope.deleteContact = (userCard, contact) => {
    var messages = {
      title: $localization.get('card.of.user.contact.delete.contact.title'),
      message: $localization.get('card.of.user.contact.delete.contact.message'),
      cancelText: $localization.get('system.no'),
      confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      userCard.contacts = userCard.contacts.filter((obj) => {
        return obj.$$hashKey != contact.$$hashKey;
      });
    });
  }
  $scope.isUserWatched = (userCard) => {
    var userId = $('current-user').data('id');
    var isWatched = false;
    userCard.watchers.forEach((watcher) => {
      if (watcher.watcherUserId == userId)
        isWatched = true;
    });
    return isWatched;
  }
  $scope.toggleWatch = (userCard) => {
    var userId = $('current-user').data('id');
    if ($scope.isUserWatched(userCard)) {
      userCard.watchers = userCard.watchers.filter((obj) => {
        return obj.watcherUserId != userId;
      });
      $http({
        url: "/api/user/watch/",
        method: "DELETE",
        params: {
          userId: userCard.id,
        }
      });
    } else {
      $http({
        url: "/api/user/watch/",
        method: "POST",
        params: {
          userId: userCard.id,
        }
      });
      userCard.watchers.push({ id: null, userId: userCard.id, watcherUserId: userId });
    }
  }
  $localization.ready().then(() => {
    moment.locale($localization.getLanguage());
    // Login as links alert types
    $scope.resetLoginAsAlertForm();
    // User global date time picker settings
    $scope.dateTimePicker = {
      timePicker: true,
      timePicker24Hour: true,
      timePickerSeconds: true,
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
      locale: {
        format: 'YYYY-MM-DD HH:mm:ss',
        applyLabel: $localization.get("system.apply"),
        cancelLabel: $localization.get("system.cancel"),
        fromLabel: $localization.get("system.from"),
        toLabel: $localization.get("system.to"),
        customRangeLabel: $localization.get("system.custom")
      }
    };
    $scope.loadOpenedCards();
    $scope.usersDataTable = $('.table-users').DataTable({
      responsive: true,
      language: {
        url: `/json/datatables/${$localization.getLanguage()}.json`
      },
      ajax: {
        url: "/api/user/all/",
        type: "GET"
      },
      columns: [
        { data: "id", className: 'font-weight-bold' },
        { data: "name" },
        { data: "email" },
        { data: "contacts", className: 'd-none d-md-table-cell' },
        { data: "roles", className: 'd-none d-lg-table-cell' }
      ],
      columnDefs: [{
        targets: 5,
        data: null,
        searchable: false,
        className: "text-right p-0 pt-2 pr-2",
        defaultContent: `<div class="text-nowrap"><button class="btn btn-sm btn-success btn-open-card"><i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>${$localization.get('system.open')}</button></div>`
      },{
        render: (data, type, row) => {
          return `<a href="mailto:${data}">${data}</a>`;
        },
        targets: 2
      },{
        render: (data, type, row) => {
          var phones = [];
          data.forEach((contact) => {
            contact.phones.forEach((phone) => {
              phones.push(phone.phone);
            });
          });
          if (phones.length == 0) {
            return `<span class="text-muted">${$localization.get('system.none')}</span>`;
          }
          return phones.join(', ');
        },
        targets: 3
      },{
        render: (data, type, row) => {
          var roles = [];
          data.forEach((role) => {
            roles.push(role.name);
          });
          if (roles.length == 0) {
            return `<span class="text-muted">${$localization.get('system.none')}</span>`;
          }
          return roles.join(', ');
        },
        targets: 4
      }],
      sDom: 't<"card-footer border-top card-footer-pagination"<"row"<"col-6"i><"col-6"p>>>'
    });
    $('.table-users tbody').on('click', 'button.btn-open-card', function(event) {
      $scope.onUserCardOpen($scope.usersDataTable.row($(this).parents('tr')).data());
      $scope.$apply();
    });
  }, (error) => {
    console.log(error);
  });
}]);