angular.module('reset', []).controller('ResetController', ['$scope', '$http', '$timeout', '$localization', ($scope, $http, $timeout, $localization) => {
  $scope.showProgress = false;
  $scope.showForm = true;
  $scope.userName = '';
  $scope.onPasswordReset = (userName) => {
    $scope.showProgress = true;
    $http({
      url: "/api/auth/reset/",
      method: "POST",
      params: {
        username: userName,
      }
    }).then((response) => {
      $timeout(() => {
        $scope.loginBoxMessage = response.data.message;
        $scope.showProgress = false;
        $scope.showForm = false;
      }, 1000);
    }, (response) => {
      $timeout(() => {
        $scope.loginBoxMessage = response.data.message;
        $scope.showProgress = false;
      }, 1000);
    });
  };
  $localization.ready().then(() => {
    $scope.loginBoxMessage = $localization.get('auth.reset.email.note');
  });
}]);