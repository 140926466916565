angular.module('tasks', ['twbs', 'ui.select2', 'ui.sortable']).controller('UserTasksController', ['$scope', '$http', '$localization', '$timeout', ($scope, $http, $localization, $timeout) => {
  $scope.newTaskLoading = false;
  $scope.editTaskLoading = false;
  $scope.currentUserId = parseInt($('current-user').data('id'));
  $scope.tasks = [];
	$scope.itemsPerPage = 32;
	$scope.page = 0;
	$scope.status = -1;
	$scope.priority = -1;
	$scope.newTaskAsignee = {};
	$scope.editTaskAsignee = {};
	$scope.editTask = {};
  $http({
    url: "/api/user/permitted/",
    method: "GET"
  }).then((response) => {
    $scope.permittedUsers = response.data;
  });
  $scope.resetNewTaskModal = () => {
    $scope.newTaskAsignee = {};
    $scope.newTaskTitle = '';
    $scope.newTaskDescription = '';
    $scope.newTaskPriority = '1';
    $scope.newTaskStatus = '0';
    $scope.newTaskLoading = false;
    $scope.alertNewTask.close();
  }
  $scope.onStatusChanged = () => {
    $scope.page = 0;
    $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
  }
  $scope.onPriorityChanged = () => {
    $scope.page = 0;
    $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
  }
  $scope.taskStateChanged = (task) => {
    task.completed = !task.completed;
    task.status = task.completed ? 2 : 0;
    $http({
      url: "/api/user/tasks/",
      method: "PUT",
      data: task
    });
  }
  $scope.onEditTask = (task) => {
    $scope.editTask = task;
    $scope.editTaskAsignee = task.asignee == null ? {} : task.asignee;
    $scope.editTaskTitle = task.title;
    $scope.editTaskDescription = task.description;
    $scope.editTaskPriority = task.priority.toString();
    $scope.editTaskStatus = task.status.toString();
    console.log(task);
  }
  $scope.onDeleteTask = (task) => {
    var messages = {
        title: $localization.get('user.tasks.delete.confirmation-title'),
        message: $localization.get('user.tasks.delete.confirmation-message'),
        cancelText: $localization.get('system.no'),
        confirmText: $localization.get('system.yes')
    };
    $scope.confirmDialog.open(messages).then((response) => {
      $http({
        url: "/api/user/tasks/",
        method: "DELETE",
        params: {
          taskId: task.id
        }
      }).then((response) => {
        $scope.alertTasks.open('SUCCESS', response.data.message, 8000);
        $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
      });
    });
  }
  $scope.onTaskSave = () => {
    $scope.editTaskLoading = true;
    $timeout(() => {
      $http({
        url: "/api/user/tasks/",
        method: "PUT",
        data: {
          id: $scope.editTask.id,
          asigneeId: $scope.editTaskAsignee.id,
          title: $scope.editTaskTitle,
          description: $scope.editTaskDescription,
          priority: $scope.editTaskPriority,
          status: $scope.editTaskStatus
        }
      }).then((response) => {
        $scope.alertEditTask.open('SUCCESS', response.data.message, 12000);
        $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
        $scope.editTaskLoading = false;
      }, (response) => {
        $scope.alertEditTask.open('DANGER', response.data.message, 12000);
        $scope.editTaskLoading = false;
      });
    }, 500);
  }
  $scope.onTaskCreateSave = () => {
    $scope.newTaskLoading = true;
    $timeout(() => {
      $http({
        url: "/api/user/tasks/",
        method: "POST",
        data: {
          asigneeId: $scope.newTaskAsignee.id,
          title: $scope.newTaskTitle,
          description: $scope.newTaskDescription,
          priority: $scope.newTaskPriority,
          status: $scope.newTaskStatus
        }
      }).then((response) => {
        $scope.alertNewTask.open('SUCCESS', response.data.message, 12000);
        $timeout(() => {
          $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
          $('#new-task-dialog').modal('toggle');
        }, 1000);
      }, (response) => {
        $scope.alertNewTask.open('DANGER', response.data.message, 12000);
        $scope.newTaskLoading = false;
      });
    }, 500);
  }
  $scope.tasksSortable = {
    stop: (e, ui) => {
      var tasks = $scope.tasks.content;
      var taskDrop = tasks[ui.item.index()];
      var taskIds = tasks.map(task => task.id);
      var taskPrevious = tasks[ui.item.index() - 1];
      var taskNext = tasks[ui.item.index() + 1];

      $http({
        url: "/api/user/tasks/sort/",
        method: "POST",
        params: {
          taskIds: taskIds
        }
      }).then((response) => {
        if (taskDrop.userId == $scope.currentUserId) {
          if (taskDrop.status == 0) {
            if (!taskPrevious && taskNext && taskDrop.priority != taskNext.priority) {
              taskDrop.priority = taskNext.priority;
            }
            
            if (taskPrevious && !taskNext && taskDrop.priority != taskPrevious.priority) {
              taskDrop.priority = taskPrevious.priority;
            }
            
            if (taskPrevious && taskNext) {
              if (taskPrevious.priority == taskNext.priority && taskDrop.priority != taskPrevious.priority) {
                taskDrop.priority = taskPrevious.priority;
              }
              if (taskPrevious.priority < taskDrop.priority && taskNext.priority < taskDrop.priority) {
                taskDrop.priority = taskPrevious.priority;
              }
              if (taskPrevious.priority > taskDrop.priority && taskNext.priority > taskDrop.priority) {
                taskDrop.priority = taskPrevious.priority;
              }
            }
          }
          
          $http({
            url: "/api/user/tasks/",
            method: "PUT",
            data: taskDrop
          }).then((response) => {
            $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
          });
        } else {
          $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
        }
      });
    },
    handle: '.handle',
    placeholder: 'sort-highlight',
    forcePlaceholderSize: true
  };
  $scope.loadTasks = (page, itemsPerPage, status, priority) => {
    $http({
      url: "/api/user/tasks/",
      method: "GET",
      params: {
        page: page,
        itemsPerPage: itemsPerPage,
        status: status,
        priority: priority
      }
    }).then((response) => {
      $scope.tasks = response.data;
      $scope.tasks.content.forEach((task) => {
        task.completed = task.status == 2;
      });
    });
	};
	$localization.ready().then(() => {
	  $scope.loadTasks($scope.page, $scope.itemsPerPage, $scope.status, $scope.priority);
	}, (error) => {
    console.log(error);
  });
}]);